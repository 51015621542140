import { createSlice } from "@reduxjs/toolkit";

const OrderListSlice = createSlice({
  name: "orderList",
  initialState: {
    orders: null,
  },
  reducers: {
    setOrderListData: (state, action) => {
      state.orders = action.payload;
    },
  },
});

export default OrderListSlice;
