import React, { useEffect } from "react";

import styles from "./DeliveryAddressChoosePage.module.scss";
import { coreDeleteRestaurantUserDeliveryAddress } from "../../api/core";
import { telegramSendSoftTaptic, webApp } from "../../services/telegram";
import { Container } from "reactstrap";
import { useCheckoutActions } from "../../store/useActions";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import { DeleteIcon } from "../../components/ui/icons/DeleteIcon";
import { AddIcon } from "../../components/ui/icons/AddIcon";
import { fetchDeliveryAddresses } from "../../services/api/delivery";
import { useCacheSelector } from "../../store/useSelectors";
import { MoneyFormat } from "../../services/utils";

const DeliveryAddressChoosePage = () => {
  const navigate = useNavigate();
  const { setDeliveryAddress } = useCheckoutActions();
  const { userDeliveryAddresses } = useCacheSelector();

  useEffect(() => {
    fetchDeliveryAddresses();
  }, []);

  const deleteUserDeliveryAddress = (e, id) => {
    e.stopPropagation();
    webApp.showConfirm("Вы хотите удалить адрес?", (isOk) => {
      if (!isOk) return;
      coreDeleteRestaurantUserDeliveryAddress(id).then((response) => {
        if (response.data) {
          fetchDeliveryAddresses(true);
        }
      });
    });
  };

  const onChangeDeliveryAddress = (item) => {
    if (item.delivery_area === null) return;
    telegramSendSoftTaptic();
    setDeliveryAddress(item);
    navigate("/");
  };

  if (!userDeliveryAddresses) {
    return <Loader atCenter={true} />;
  }

  return (
    <>
      <DeliveryAddress isChoose={true} />
      <div className={styles.addressPage}>
        <Container className="h-100 pb-5" fluid>
          <h5 className={styles.addressPage__title}>Адрес доставки</h5>
          <div className={styles.addressPage__body}>
            <div className={styles.addressPage__items}>
              {userDeliveryAddresses &&
                userDeliveryAddresses.map((item) => {
                  return (
                    <article
                      key={item.id}
                      onClick={() => onChangeDeliveryAddress(item)}
                      className={
                        item.delivery_area === null
                          ? [styles.addressPage__item, styles.opacity].join(" ")
                          : styles.addressPage__item
                      }
                    >
                      <p style={{ width: "80%" }}>
                        <p>{item.text}</p>
                        {item?.delivery_area?.minimal_cost ? (
                          <span style={{ color: "var(--hint-color)" }}>
                            <br />
                            Минимальный заказ от{" "}
                            {MoneyFormat.format(item.delivery_area.minimal_cost)}
                          </span>
                        ) : null}
                        {item?.delivery_area?.cost ? (
                          <span style={{ color: "var(--hint-color)" }}>
                            <br />
                            Стоимость доставки {MoneyFormat.format(item.delivery_area.cost)}
                          </span>
                        ) : null}
                        {item.delivery_area === null && (
                          <span style={{ color: "var(--hint-color)" }}>
                            <br /> Данный адрес недоступен
                          </span>
                        )}
                      </p>
                      <div
                        onClick={(e) => deleteUserDeliveryAddress(e, item.id)}
                        className={styles.addressPage__delete}
                      >
                        <DeleteIcon color="var(--text-color)" />
                      </div>
                    </article>
                  );
                })}
            </div>

            <Link
              to={"/delivery-address/new?redirect_url=/delivery-address/choose"}
              className={styles.addressPage__add}
            >
              <p>Добавить</p>
              <AddIcon color="var(--text-color)" />
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DeliveryAddressChoosePage;
