import React from "react";

const ProductCardFeaturedBage = () => {
  return (
    <>
      <svg
        className="product-card__badge"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" rx="10" fill="var(--button-color)" />
        <path
          d="M11.7391 23.8403C11.7391 23.8403 7.5 23.4055 7.5 17.6447C7.5 13.1882 10.8695 10.5795 10.8695 10.5795C10.8695 10.5795 12.2826 12.1013 13.3695 12.6447C14.4565 13.2969 14.8913 6.6665 14.8913 6.6665C14.8913 6.6665 22.7173 10.9056 22.7173 17.3186C22.7173 23.949 18.3695 23.7316 18.3695 23.7316C18.3695 23.7316 20.326 21.1229 20.326 18.0795C20.326 14.8186 16.0869 10.7969 16.0869 10.7969C16.0869 10.7969 15.5434 15.5795 13.8043 16.2317C12.0652 15.2534 11.0869 13.7317 11.0869 13.7317C11.0869 13.7317 7.06522 20.036 11.7391 23.8403Z"
          fill="white"
        />
        <path
          d="M15.326 24.1672C13.1521 24.0585 11.3043 22.6455 11.3043 20.689C11.3043 18.7325 12.0652 17.8629 12.0652 17.8629C12.0652 17.8629 12.6087 18.9499 13.2608 19.4933C13.913 20.0368 15.2173 20.3629 15.8695 19.602C16.5217 18.9499 16.7391 17.1021 16.7391 17.1021C16.7391 17.1021 18.2608 18.2977 18.0434 20.3629C17.9347 22.5368 17.0652 24.1672 15.326 24.1672Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ProductCardFeaturedBage;
