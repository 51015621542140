import { coreClient, coreTelegramUserAuth } from "../../api/core";
import { webApp } from "./../telegram";
import UserSlice from "../../store/slices/user";
import { store } from "../../store/store";

export const fetchUserToken = (force) => {
  const { setToken } = UserSlice.actions;
  if (force) {
    window.localStorage.removeItem("token");
  }
  const token = window.localStorage.getItem("token");
  if (token) {
    coreClient.defaults.headers.common["Authorization"] = "Bearer " + token;
    store.dispatch(setToken(token));
    return;
  }
  coreTelegramUserAuth(webApp.initData).then((response) => {
    if (response) {
      coreClient.defaults.headers.common["Authorization"] = "Bearer " + response.data.key;
      window.localStorage.setItem("token", response.data.key);
      store.dispatch(setToken(response.data.key));
    }
  });
};
