import React from "react";
import { Spinner } from "reactstrap";

const ProductCardButtonPrice = ({ price, salePrice }) => {
  return (
    <>
      <span className="price-btn-discount">{salePrice} ₽</span>
      <span
        style={{
          textDecoration: "line-through",
          marginLeft: "0.5rem",
          fontSize: "0.9em",
          opacity: "0.6",
        }}
      >
        {price} ₽
      </span>
    </>
  );
};

const ProductCardButton = ({ price, salePrice, ...props }) => {
  return (
    <div className="btn-price-wrapper" {...props}>
      <button className="price-btn  position-absolute bottom-0 ">
        {salePrice ? <ProductCardButtonPrice price={price} salePrice={salePrice} /> : price + " ₽"}
      </button>
    </div>
  );
};

export default ProductCardButton;
