import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import { BottomSheet } from "react-spring-bottom-sheet";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import {
  coreCreateRestaurantUserDeliveryAddress,
  coreGetAddressSuggestions,
  coreGetRestaurantDeliveryAreas,
} from "../api/core";
import MainButton from "../components/MainButton";
import Loader from "../components/Loader";
import { Icon } from "leaflet/src/layer";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { getCenterOfPolygon } from "../services/geo";
import { useCacheSelector } from "../store/useSelectors";
import { useCacheActions } from "../store/useActions";

const AddressInput = ({
  isActive,
  inputValue,
  setInputValue,
  setAddressSuggestions,
  setLoader,
  inputProps,
  ...props
}) => {
  const [value, setValue] = useState(inputValue ? inputValue.text : "");

  const onChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (inputValue) {
      setValue(inputValue.text);
    }
  }, [inputValue]);

  const fetchAddresses = (value) => {
    setLoader(true);
    coreGetAddressSuggestions(value).then((response) => {
      if (response && response.data) {
        setLoader(false);
        setAddressSuggestions(response.data);
      }
    });
  };

  useEffect(() => {
    let timeout = null;
    if (isActive && value) {
      setAddressSuggestions([]);
      setLoader(true);
      timeout = setTimeout(() => {
        fetchAddresses(value);
      }, 2000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [value]);

  inputProps = inputProps || {};

  return (
    <div className="delivery-address__address-search-group" {...props}>
      <svg viewBox="0 0 24 24" aria-hidden="true" className="delivery-address__address-search-icon">
        <g>
          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
        </g>
      </svg>
      <input
        className="delivery-address__address-input"
        type="search"
        placeholder="Адрес"
        onChange={onChange}
        style={{ paddingLeft: "2.5rem" }}
        value={value}
        {...inputProps}
      />
    </div>
  );
};

const DisplayMap = ({ mapPosition, setMap, userPosition, deliveryAreas, ...props }) => {
  const displayMap = useMemo(() => (
    <MapContainer
      center={[59.939994, 30.328857]}
      zoom={10}
      zoomControl={false}
      style={{ width: "100vw", height: "100vh" }}
      ref={setMap}
    >
      <TileLayer
        attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {deliveryAreas.map((deliveryArea, idx) => {
        return (
          <Polygon
            key={idx}
            positions={deliveryArea.area.coordinates.map((e) => {
              return e.map((ee) => [...ee].reverse());
            })}
          >
            <Popup>
              <b>{deliveryArea.name}</b>
              <br />
              Минимальная сумма заказа: {deliveryArea.minimal_cost}
              <br />
              Стоимость доставки: {deliveryArea.cost}
            </Popup>
          </Polygon>
        );
      })}
      {userPosition ? (
        <Marker
          position={userPosition}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
        >
          <Popup>Вы находитесь здесь</Popup>
        </Marker>
      ) : null}
    </MapContainer>
  ));

  return displayMap;
};

const BottomMenu = ({ flyTo, setUserPosition, ...props }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect_url = queryParams.get("redirect_url");

  const { setUserDeliveryAddresses } = useCacheActions();

  const [suggestionsLoader, setSuggestionsLoader] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [addressBottomSheetOpened, setAddressBottomSheetOpened] = useState(false);
  const [addressInputValue, setAddressInputValue] = useState(null);

  useEffect(() => {
    if (addressInputValue) {
      setUserPosition([addressInputValue.latitude, addressInputValue.longitude]);
      flyTo([addressInputValue.latitude, addressInputValue.longitude], 17);
    }
  }, [addressInputValue]);

  useEffect(() => {
    if (addressInputValue) {
      setTimeout(() => document.querySelector(".delivery-address__address-input").blur(), 300);
    }
  }, [addressInputValue]);

  const onSubmit = (dirtyData) => {
    const data = {};
    Object.keys(dirtyData).forEach((key) => {
      if (dirtyData[key]) {
        data[key] = dirtyData[key];
      }
    });
    coreCreateRestaurantUserDeliveryAddress(data).then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        setUserDeliveryAddresses(null);
        navigate(redirect_url);
      }
    });
  };

  return (
    <>
      <div className={"bottom-banner"}>
        <Container fluid>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <AddressInput
              // Без таймайута баг на iOS: Из-за открытия клавиатуры меняется maxHeight и всё идёт не туда
              onClick={() => {
                setTimeout(() => setAddressBottomSheetOpened(true), 100);
              }}
              inputValue={addressInputValue}
              inputProps={{
                ...register("text", { required: true }),
              }}
            />

            <Row className={"pt-3"}>
              <Col xs={4}>
                <input
                  className="delivery-address__address-input"
                  placeholder="Подъезд"
                  data-index={"2"}
                  type={"number"}
                  {...register("entrance")}
                />
              </Col>
              <Col xs={4}>
                <input
                  className="delivery-address__address-input"
                  placeholder="Этаж"
                  data-index={"3"}
                  type={"number"}
                  {...register("floor")}
                />
              </Col>
              <Col xs={4}>
                <input
                  className="delivery-address__address-input"
                  placeholder="Кв"
                  data-index={"4"}
                  type={"number"}
                  {...register("flat")}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <BottomSheet
        open={addressBottomSheetOpened}
        onDismiss={() => setAddressBottomSheetOpened(false)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
      >
        <Container style={{ padding: "1rem 0" }}>
          <AddressInput
            isActive={true}
            inputValue={addressInputValue}
            setInputValue={setAddressInputValue}
            setAddressSuggestions={setAddressSuggestions}
            setLoader={setSuggestionsLoader}
          />
          {suggestionsLoader ? (
            <div className={"mt-3"}>
              <Loader />
            </div>
          ) : null}
          {addressSuggestions.length > 0 ? (
            <Row className={"p-3"}>
              {addressSuggestions.map((e, idx) => (
                <Col
                  key={idx}
                  xs={12}
                  className={"mt-3"}
                  style={{ borderRadius: "40px" }}
                  onClick={() => {
                    setAddressInputValue(e);
                    setAddressBottomSheetOpened(false);
                  }}
                >
                  <Card>
                    <CardBody>{e.text}</CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : null}
        </Container>
      </BottomSheet>
      {addressBottomSheetOpened || !isValid ? null : (
        <MainButton text={"Добавить"} onClick={handleSubmit(onSubmit)} />
      )}
    </>
  );
};

const CreateDeliveryAddressPage = (props) => {
  const [map, setMap] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const { deliveryAreas } = useCacheSelector();
  const { setDeliveryAreas } = useCacheActions();

  useEffect(() => {
    if (deliveryAreas === null) fetchDeliveryAreas();
  }, []);

  useEffect(() => {
    if (map) {
      map.attributionControl.setPrefix("");
    }
  }, [map]);

  useEffect(() => {
    if (deliveryAreas && deliveryAreas.length > 0) {
      flyTo(getCenterOfPolygon(deliveryAreas[0].area.coordinates[0]), 10);
    }
  }, [deliveryAreas]);

  const flyTo = (coords, zoom = 10) => {
    if (map) {
      map.flyTo(coords, zoom, { duration: 0.5 });
    }
  };

  const fetchDeliveryAreas = () => {
    coreGetRestaurantDeliveryAreas().then((response) => {
      setDeliveryAreas(response.data);
    });
  };

  if (deliveryAreas === null) return <Loader atCenter={true} />;

  return (
    <>
      <DisplayMap userPosition={userPosition} setMap={setMap} deliveryAreas={deliveryAreas} />
      <BottomMenu flyTo={flyTo} setUserPosition={setUserPosition} />
    </>
  );
};

export default CreateDeliveryAddressPage;
