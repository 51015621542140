import React, { useEffect, useRef } from "react";
import ProductPageCheckbox from "./ProductPageCheckbox";
import { getDescriptionText } from "../../utils/getDescriptionText";

const ProductPageGroup = ({
  props,
  setPrice,
  price,
  currentOptions,
  setCurrentOptions,
  activeRequiredOptions,
  setActiveRequiredOptions,
  errorOptions,
  scrollToItem,
  setScrollToItem,
}) => {
  const [currentSelected, setCurrentSelected] = React.useState(0);
  const [activeCheckbox, setActiveCheckbox] = React.useState([]);
  const [errorStyle, setErrorStyle] = React.useState(false);
  const myRef = useRef();

  useEffect(() => {
    if (scrollToItem === props.id) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollToItem();
    }
  }, [scrollToItem]);

  useEffect(() => {
    if (props.max_selected) {
      setCurrentSelected(props.max_selected);
    }
    if (props.max_selected == null) {
      setCurrentSelected(Infinity);
    }
  }, []);

  useEffect(() => {
    if (props.min_selected > 0) {
      if (errorOptions.indexOf(props.id) !== -1) {
        setErrorStyle(true);
      } else {
        setErrorStyle(false);
      }
    }
  }, [errorOptions]);

  useEffect(() => {
    if (props.min_selected > 0) {
      const activeIndex = activeRequiredOptions.indexOf(props.id);
      if (activeCheckbox.length >= props.min_selected && activeIndex === -1) {
        setActiveRequiredOptions((prevOptions) => {
          return [...prevOptions, props.id];
        });
      }
      if (activeCheckbox.length < props.min_selected && activeIndex > 0) {
        setActiveRequiredOptions((prevOptions) => {
          return prevOptions.filter((id) => id !== props.id);
        });
      }
    }
  }, [activeCheckbox]);

  const onChangeActiveCheckbox = (id, checked, addPrice) => {
    if (checked && activeCheckbox.length < currentSelected) {
      setActiveCheckbox([...activeCheckbox, id]);
      setCurrentOptions([...currentOptions, id]);
      setPrice(price + addPrice);
    } else {
      const current = activeCheckbox.filter((el) => el !== id);
      const currentAll = currentOptions.filter((el) => el !== id);
      setCurrentOptions(currentAll);
      setActiveCheckbox(current);
      setPrice(price - addPrice);
    }
  };

  let descriptionText = getDescriptionText(props);

  return (
    <div ref={myRef} className="product-page__group">
      <div className={"mb-3"}>
        <h5
          className={
            errorStyle
              ? ["prooduct-page__error", "product-page__title-group"].join(" ")
              : "product-page__title-group"
          }
        >
          {props.title}
        </h5>
        {descriptionText && <p className="product-page__group-label">{descriptionText}</p>}
      </div>
      {props.options.map((e) => (
        <ProductPageCheckbox
          key={e.id}
          props={e}
          onChangeActiveCheckbox={onChangeActiveCheckbox}
          activeCheckbox={activeCheckbox}
          max={props.max_selected}
        />
      ))}
    </div>
  );
};

export default ProductPageGroup;
