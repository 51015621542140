import React, { useState } from "react";
import Loader from "./Loader";
import { getRandomInt } from "../services/utils";
import { useRestaurantSelector } from "../store/useSelectors";

const ImageWithLoader = ({ src, loaderStyle, hideLoaderText, ...props }) => {
  const [imgSrc, setImgSrc] = useState("");
  const { tariff } = useRestaurantSelector();

  const isPriorityLoading = tariff.options.PRIORITY_PHOTO_LOADING;

  const image = new Image();
  image.onload = () => {
    setImgSrc(src);
  };
  if (!isPriorityLoading) {
    setTimeout(() => (image.src = src), getRandomInt(1000, 2000));
  } else {
    image.src = src;
  }

  return imgSrc === "" ? (
    <Loader loaderStyle={loaderStyle} hideLoaderText={hideLoaderText} />
  ) : (
    <img src={imgSrc} {...props} alt={"loader-img"} />
  );
};

export default ImageWithLoader;
