import { store } from "../store/store";

export const restaurantLocalStorageSetItem = (key, value) => {
  const restaurantId = store.getState().restaurant.restaurantId;
  return localStorage.setItem(`${restaurantId}.${key}`, value);
};

export const restaurantLocalStorageGetItem = (key) => {
  const restaurantId = store.getState().restaurant.restaurantId;
  return localStorage.getItem(`${restaurantId}.${key}`);
};
