import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { coreCreateOrderPaymentlink, coreGetOrder } from "../api/core";
import Loader from "../components/Loader";
import {
  telegramNotificationOccurredSuccess,
  telegramOpenInvoice,
  telegramShowAlert,
} from "../services/telegram";

const OrderPayPage = () => {
  const { id } = useParams();
  const [paymentLink, setPaymentLink] = useState(null);
  const navigate = useNavigate();

  let checkInterval = null;
  let checksCount = 0;

  useEffect(() => {
    coreCreateOrderPaymentlink(id)
      .then((response) => {
        if (response) {
          setPaymentLink(response.data.payment_link);
        } else {
          navigateToOrder();
        }
      })
      .catch(() => {
        telegramShowAlert("Не удалось получить ссылку для оплаты заказа", () => navigateToOrder());
      });

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (paymentLink) {
      telegramOpenInvoice(paymentLink, invoiceCallback);
    }
  }, [paymentLink]);

  const navigateToOrder = () => {
    navigate(`/orders/${id}`);
  };

  const checkOrderIsPayed = () => {
    if (checksCount > 5) {
      clearInterval(checkInterval);
      telegramShowAlert(
        "Платежи обрабатываются с задержками, но мы уже начали готовить ваш заказ",
        () => navigateToOrder(),
      );
      return;
    }

    coreGetOrder({ id: id, expanded: false }).then((response) => {
      if (response?.data.payed_at) {
        telegramNotificationOccurredSuccess();
        clearInterval(checkInterval);
        navigateToOrder();
        return;
      }
      checksCount += 1;
    });
  };

  const invoiceCallback = (isOk) => {
    if (isOk) {
      checkInterval = setInterval(() => checkOrderIsPayed(), 2000);
    }
  };

  return <Loader atCenter={true} />;
};

export default OrderPayPage;
