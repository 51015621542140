import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UISlice from "./slices/ui";
import RestaurantSlice from "./slices/restaurant";
import UserSlice from "./slices/user";
import CheckoutSlice from "./slices/checkout";
import OrderSlice from "./slices/order";
import OrderListSlice from "./slices/orderList";
import CacheSlice from "./slices/cache";

const rootReducer = combineReducers({
  ui: UISlice.reducer,
  restaurant: RestaurantSlice.reducer,
  user: UserSlice.reducer,
  checkout: CheckoutSlice.reducer,
  order: OrderSlice.reducer,
  orderList: OrderListSlice.reducer,
  cache: CacheSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
