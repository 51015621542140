import React from "react";
import { getPhotoThumbnail } from "../../services/media";
import ImageWithLoader from "../ImageWithLoader";

const ProductCardImage = (props) => {
  const thumbnail = getPhotoThumbnail(props.src);

  return <ImageWithLoader className="product-img" src={thumbnail} alt="product" loading="lazy" />;
};

export default ProductCardImage;
