import React from "react";
import { Col, Row } from "reactstrap";
import CartItem from "./CartItem";

const CartItemList = ({ cartItems, ...props }) => {
  return (
    <Row {...props}>
      {cartItems.map((item, idx) => (
        <Col key={idx} xs={12} sm={12} md={12} className="cart__border">
          <CartItem item={item} />
        </Col>
      ))}
    </Row>
  );
};

export default CartItemList;
