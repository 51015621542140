import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styles from "./ProgressIndicator.module.scss";
import { CourierIcon } from "../ui/icons/CourierIcon";

const ProgressIndicatorIcon = ({ progressValue, type, min }) => {
  return (
    <div className={type === "static" ? styles.bottomsheet__progress : ""}>
      <div
        className={
          type === "static" ? [styles.indicator, styles.static].join(" ") : styles.indicator
        }
      >
        <div className={styles.indicator__wrapper}>
          <CircularProgressbar
            value={progressValue}
            styles={buildStyles({
              pathColor: "var(--button-color)",
              trailColor: "var(--text-color)",
            })}
          />
          <CourierIcon color="var(--text-color)" className={styles.indicator__icon} />
        </div>
      </div>
      {type === "static" && (
        <div className={styles.bottomsheet__values}>
          <p>Ещё {min} ₽</p>
          <span>До минимальной суммы</span>
        </div>
      )}
    </div>
  );
};

export default ProgressIndicatorIcon;
