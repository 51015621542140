export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
};

export const MoneyFormat = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const getFormattedBonuses = (amount) => {
  const formatter = new Intl.NumberFormat("ru-RU");

  const value = formatter.format(amount);

  return `${value} ₿`;
};
