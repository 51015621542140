import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    cart: {},
    userData: null,
    isLoading: false,

    restaurantUserIsBlocked: null,
    userNotFound: null,
    restaurantUserNotFound: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setRestaurantUserIsBlocked: (state, action) => {
      state.restaurantUserIsBlocked = action.payload;
    },
    setUserNotFound: (state, action) => {
      state.userNotFound = action.payload;
    },
    setRestaurantUserNotFound: (state, action) => {
      state.restaurantUserNotFound = action.payload;
    },
  },
});

export default UserSlice;
