import { useRestaurantSelector, useUserSelector } from "../store/useSelectors";
import { Col, Container, Row } from "reactstrap";
import Lottie from "lottie-react";
import React, { useEffect } from "react";
import stickerTrickyFoxNoNo from "../assets/lottie/tricky_fox/no_no.json";
import stickerLittleMoleSearch from "../assets/lottie/little_mole/search.json";
import stickerUtyaDuckPrivate from "../assets/lottie/utya_duck/private.json";

const ErrorContainer = ({ sticker, text, spanText, mainButton, ...props }) => (
  <>
    <Container>
      <Row
        className="d-flex align-items-center justify-content-center text-center"
        style={{ marginTop: "50%" }}
      >
        <Col xs={12} className="justify-content-center d-flex">
          <Lottie className="w-50" animationData={sticker} />
        </Col>
        <Col xs={12} className={"mt-5"}>
          <h3>{text}</h3>
          <span style={{ color: "var(--hint-color)" }}>{spanText}</span>
        </Col>
      </Row>
    </Container>
    {mainButton}
  </>
);

const ErrorHandler = ({ children, ...props }) => {
  const { restaurantUserIsBlocked, userNotFound, restaurantUserNotFound } = useUserSelector();
  const { restaurantNotFound } = useRestaurantSelector();

  useEffect(() => {
    if (userNotFound || restaurantUserNotFound) {
      localStorage.removeItem("token");
    }
  }, [userNotFound, restaurantUserNotFound]);

  if (restaurantUserIsBlocked) {
    return (
      <ErrorContainer
        sticker={stickerTrickyFoxNoNo}
        text={"Ваш аккаунт заблокирован"}
        spanText={"Обратитесь к администратору за подробной информацией"}
      />
    );
  } else if (userNotFound || restaurantUserNotFound) {
    return (
      <ErrorContainer
        sticker={stickerUtyaDuckPrivate}
        text={"Ошибка идентификации"}
        spanText={"Перезапустите бота, чтобы обновить секретный ключ"}
      />
    );
  } else if (restaurantNotFound) {
    return (
      <ErrorContainer
        sticker={stickerLittleMoleSearch}
        text={"Ресторан не найден"}
        spanText={"Возможно что-то пошло не так: попробуйте перезапустить бота"}
      />
    );
  }

  return children;
};

export default ErrorHandler;
