import React from "react";
import chatupperLogo from "../assets/chatupper.png";
import { Col, Row } from "reactstrap";

export const LogoLoader = ({ className, ...props }) => {
  const classes =
    "d-flex align-items-center justify-content-center text-center logo-loader " + (className || "");
  return (
    <Row className={classes} {...props}>
      <Col xs={12}>
        <img src={chatupperLogo} alt={"logo"} />
      </Col>
      <Col xs={12}>
        <div className={"h6"}>Кафеботум</div>
      </Col>
    </Row>
  );
};

const TestPage = () => {
  return <LogoLoader />;
};

export default TestPage;
