import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Col, Container, Input, Row } from "reactstrap";
import MainButton from "../components/MainButton";
import VerticalButtons from "../components/VerticalButtons";
import {
  useCacheSelector,
  useCheckoutSelector,
  useRestaurantSelector,
  useUserSelector,
} from "../store/useSelectors";
import {
  coreCreateOrder,
  coreDeleteRestaurantUserDeliveryAddress,
  coreGetCart,
  coreGetCurrentRestaurant,
  coreGetRestaurantUser,
  corePatchCartPromocode,
} from "../api/core";
import {
  useCacheActions,
  useCheckoutActions,
  useRestaurantActions,
  useUIActions,
  useUserActions,
} from "../store/useActions";
import { ColSceleton } from "../components/Sceletons";
import { telegramSelectionChanged, telegramShowAlert, webApp } from "../services/telegram";
import { store } from "../store/store";
import { useNavigate } from "react-router-dom";
import TotalPricing from "../components/Checkout/TotalPricing";
import { TDeliveryType, deliveryTypeMap, paymentMethodMap, TPaymentMethod } from "../data/consts";
import { Swiper, SwiperSlide } from "swiper/react";
import TrashIcon from "../components/ui/icons/TrashIcon";
import Loader from "../components/Loader";
import { fetchDeliveryAddresses } from "../services/api/delivery";
import BonusesCard from "../components/Checkout/BonusesCard/BonusesCard";
import { CloseIcon } from "../components/ui/icons/CloseIcon";
import { SpinnerIcon } from "../components/ui/icons/SpinnerIcon";

const CheckoutPage = () => {
  const { deliveryType, paymentMethodOption, deliveryAddress, phoneNumber, comment, tableNumber } =
    useCheckoutSelector();

  const { userDeliveryAddresses } = useCacheSelector();

  const { setUserDeliveryAddresses } = useCacheActions();

  const {
    setDeliveryType,
    setPaymentMethodOption,
    setDeliveryAddress,
    setPhoneNumber,
    setComment,
    setTableNumber,
  } = useCheckoutActions();

  const [addressInputValid, setAddressInputValid] = useState(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [tableNumberValid, setTableNumberValid] = useState(true);
  const [cartLoading, setCartLoading] = useState(false);
  const [promocodeLoading, setPromocodeLoading] = useState(false);
  const [currentPromocode, setCurrentPromocode] = useState("");
  const [isActivePromocode, setIsActivePromocode] = useState(false);

  const { settings, tariff, categories } = useRestaurantSelector();
  const { cart, userData } = useUserSelector();
  const { setRestaurant } = useRestaurantActions();
  const { setCart, setUserData, setIsLoading } = useUserActions();
  const { setHideBackButton } = useUIActions();
  const [bonuses, setBonuses] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(settings).length === 0) {
      coreGetCurrentRestaurant().then((response) => setRestaurant(response.data));
      setCartLoading(true);
      coreGetCart()
        .then((response) => {
          setCart(response.data);
        })
        .finally(() => setCartLoading(false));
    }
    if (!userData) coreGetRestaurantUser().then((response) => setUserData(response.data));
  }, []);

  useEffect(() => {
    setCartLoading(true);
    const orderData = {};

    if (deliveryAddress && deliveryType === TDeliveryType.DELIVERY) {
      orderData.longitude = deliveryAddress.point.coordinates[0];
      orderData.latitude = deliveryAddress.point.coordinates[1];
    }

    if (settings.bonus_enabled) {
      orderData.bonuses = bonuses;
    }

    coreGetCart(orderData)
      .then((response) => {
        if (response) setCart(response.data);

        if (response?.data?.restaurant_promocode?.code) {
          setCurrentPromocode(response?.data?.restaurant_promocode?.code);
          setIsActivePromocode(true);
        }
      })
      .finally(() => setCartLoading(false));
  }, [deliveryAddress, deliveryType, bonuses, isActivePromocode]);

  useEffect(() => {
    userData && setPhoneNumber(userData.user.phone_number);
  }, [userData]);

  useEffect(() => {
    if (deliveryType === TDeliveryType.DELIVERY) {
      fetchDeliveryAddresses();
    }
  }, [deliveryType]);

  const onClickpickupOption = (optionIndex) => {
    setDeliveryType(settings.delivery_types[optionIndex]);
    telegramSelectionChanged();
  };
  const onClickpaymentOption = (optionIndex) => {
    setPaymentMethodOption(optionIndex);
    telegramSelectionChanged();
  };

  const isOrderDataValid = (data) => {
    if (data.deliveryType === TDeliveryType.DELIVERY && !data.deliveryAddress) {
      console.info("deliveryAddress invalid");
      telegramShowAlert("Для того, чтобы заказать доставку — нужно указать адрес");
      setAddressInputValid(false);
      return false;
    }
    if (!data.phoneNumber) {
      console.info("phoneNumber invalid");
      setPhoneNumberValid(false);
      telegramShowAlert("Пожалуйста, укажите телефон, чтобы мы могли с вами связаться");
      return false;
    }

    if (data.deliveryType === TDeliveryType.TABLE && !data.tableNumber) {
      console.info("tableNumber invalid");
      setTableNumberValid(false);
      telegramShowAlert("Пожалуйста, укажите номер столика, чтобы мы смогли принести заказ");
      return false;
    }
    return true;
  };

  const createOrder = () => {
    const data = store.getState().checkout;
    if (!isOrderDataValid(data)) return;
    setIsLoading(true);

    const orderData = {
      paymentMethod: settings.payment_methods[data.paymentMethodOption],
      deliveryType: deliveryType,
      phoneNumber: data.phoneNumber,
      comment: data.comment,
      deliveryAddressId: data.deliveryAddress ? data.deliveryAddress.id : null,
      tableNumber: data.tableNumber,
    };

    if (settings.bonus_enabled) {
      orderData.bonuses = bonuses;
    }

    coreCreateOrder(orderData)
      .then((response) => {
        if (response) {
          setHideBackButton(true);
          if (response.data.payment_method === TPaymentMethod.ONLINE) {
            //   Тут отправляем на страницу оплаты заказа
            navigate(`/orders/${response.data.id}/pay`);
          } else {
            navigate(`/orders/${response.data.id}`);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const deleteUserDeliveryAddress = (id) => {
    webApp.showConfirm("Вы хотите удалить адрес?", (isOk) => {
      if (!isOk) return;
      coreDeleteRestaurantUserDeliveryAddress(id).then((response) => {
        if (response.data) {
          setUserDeliveryAddresses(null);
          fetchDeliveryAddresses(true);
        }
      });
    });
  };

  const onChangeActivePromocode = (isActivePromocode) => {
    setPromocodeLoading(true);
    if (!isActivePromocode) {
      corePatchCartPromocode(currentPromocode)
        .then((response) => {
          if (response?.status === 200) {
            setIsActivePromocode(true);
          }
        })
        .finally(() => setPromocodeLoading(false));
    } else {
      corePatchCartPromocode(null)
        .then((response) => {
          if (response?.status === 200) {
            setIsActivePromocode(false);
          }
        })
        .finally(() => setPromocodeLoading(false));
    }
  };

  if (Object.keys(settings).length === 0)
    return (
      <Container>
        <Row className="py-5">
          <ColSceleton xs={12} md={4} lg={2} />
        </Row>
        <Row className="py-3">
          <ColSceleton xs={12} md={4} lg={2} />
        </Row>
      </Container>
    );

  return (
    <>
      <Container className={"pt-3"}>
        <h6 className="payment-form-title">Способ получения</h6>

        <VerticalButtons
          activeOption={settings.delivery_types.indexOf(deliveryType)}
          onClickOption={onClickpickupOption}
          optionsArr={settings.delivery_types.map((e) => deliveryTypeMap[e])}
        />

        <h6 className="payment-form-title">Способ оплаты</h6>
        <VerticalButtons
          activeOption={paymentMethodOption}
          onClickOption={onClickpaymentOption}
          optionsArr={settings.payment_methods.map((e) => paymentMethodMap[e])}
        />

        {/*Выбор адреса*/}
        {deliveryType === TDeliveryType.DELIVERY ? (
          <div>
            <h6 className="payment-form-title">Адрес доставки</h6>
            {userDeliveryAddresses !== null ? (
              <Swiper initialSlide={0} cssMode={true} slidesPerView={2.3}>
                {userDeliveryAddresses.map((da, idx) => (
                  <SwiperSlide key={idx}>
                    <Card
                      className={`delivery-address-card`}
                      onClick={() => setDeliveryAddress(da)}
                      style={{
                        border:
                          deliveryAddress?.id === da.id ? "1px solid var(--button-color)" : null,
                      }}
                    >
                      <CardBody>{da.text}</CardBody>
                      {deliveryAddress?.id === da.id ? (
                        <CardFooter className={"text-center"}>
                          <TrashIcon
                            height={"16"}
                            width={"16"}
                            onClick={() => deleteUserDeliveryAddress(da.id)}
                          />
                        </CardFooter>
                      ) : null}
                    </Card>
                  </SwiperSlide>
                ))}
                <SwiperSlide>
                  <Card
                    className={`delivery-address-card`}
                    style={{ border: "1px dashed var(--secondary-bg-color)" }}
                    onClick={() => navigate("/delivery-address/new?redirect_url=/checkout")}
                  >
                    <CardBody style={{ textAlign: "center" }}>
                      <Row>
                        <Col xs={12}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="24"
                            height="24"
                            viewBox="0 0 50 50"
                            fill="var(--hint-color)"
                          >
                            <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z"></path>
                          </svg>
                        </Col>
                        <Col xs={12} className={"mt-1"} style={{ color: "var(--hint-color)" }}>
                          Добавить
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              </Swiper>
            ) : (
              <Loader className="mb-5" loaderStyle={{ height: "0px" }} />
            )}
          </div>
        ) : null}

        <div style={{ paddingBottom: "25px" }}>
          <h6 className="payment-form-title">Дополнительно</h6>
          {deliveryType === TDeliveryType.TABLE ? (
            <Input
              placeholder="Номер столика"
              type={"number"}
              min={"1"}
              invalid={!tableNumberValid}
              value={tableNumber}
              onChange={(event) => {
                setTableNumber(event.target.value);
                setTableNumberValid(true);
              }}
            />
          ) : null}
          <Input
            invalid={!phoneNumberValid}
            style={{ marginTop: "8px" }}
            placeholder="Номер телефона"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumberValid(true);
              setPhoneNumber(event.target.value);
            }}
          />
          <Input
            type="textarea"
            style={{ marginTop: "8px" }}
            placeholder="Комментарий"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
          {tariff.options.PROMOCODES ? (
            <>
              <div className="position-relative">
                <Input
                  disabled={cartLoading || isActivePromocode}
                  type="text"
                  style={{
                    marginTop: "8px",
                    paddingRight: "100px",
                    border: isActivePromocode ? "1px solid var(--button-color) !important" : "none",
                    opacity: cartLoading || isActivePromocode || promocodeLoading ? "0.7" : "1",
                  }}
                  placeholder="Промокод"
                  spellCheck={false}
                  value={currentPromocode}
                  onChange={(event) => setCurrentPromocode(event.target.value)}
                />

                <button
                  style={{ pointerEvents: promocodeLoading ? "none" : "auto" }}
                  onClick={() => onChangeActivePromocode(isActivePromocode)}
                  className="promocode__button"
                >
                  {promocodeLoading ? (
                    <SpinnerIcon className={"promocode__spinner"} color={"var(--hint-color)"} />
                  ) : isActivePromocode ? (
                    <CloseIcon color={"var(--hint-color)"} />
                  ) : (
                    <div style={{ color: "var(--hint-color)" }}>
                      {currentPromocode?.length ? "применить" : null}
                    </div>
                  )}
                </button>
              </div>
            </>
          ) : null}
        </div>
        {settings.bonus_enabled && cart.bonuses_can_spend ? (
          <BonusesCard
            cart={cart}
            cartLoading={cartLoading}
            bonuses={bonuses}
            setBonuses={setBonuses}
          />
        ) : null}
        <TotalPricing
          settings={settings}
          cart={cart}
          cartLoading={cartLoading}
          className={"mb-5"}
        />
      </Container>
      {!cart ? null : <MainButton text={"Заказать — " + cart.total + " ₽"} onClick={createOrder} />}
    </>
  );
};

export default CheckoutPage;
