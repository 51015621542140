import React from "react";
import { getPhotoThumbnail } from "../../services/media";
import ImageWithLoader from "../ImageWithLoader";

import styles from "./PopularProductList.module.scss";

const PopularProductListImage = (props) => {
  const thumbnail = getPhotoThumbnail(props.photo);

  return (
    <ImageWithLoader
      className={styles.PopularProduct__image}
      src={thumbnail}
      alt="product"
      loading="lazy"
    />
  );
};

export default PopularProductListImage;
