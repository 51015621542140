import React, { useCallback, useEffect, useState } from "react";
import { useOrderListSelector, useUserSelector } from "../store/useSelectors";
import { useOrderListActions, useUserActions } from "../store/useActions";
import Loader from "../components/Loader";
import { coreGetOrderList, coreGetRestaurantUser } from "../api/core";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import OrderCard from "../components/OrderCard";
import ProfileCard from "../components/ProfileCard";
import { store } from "../store/store";

const OrderListPage = () => {
  const { orders } = useOrderListSelector();
  const { setOrderListData } = useOrderListActions();
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setUserData } = useUserActions();
  const { userData } = useUserSelector();

  useEffect(() => {
    coreGetRestaurantUser().then((response) => {
      setUserData(response.data);
    });
  }, []);

  useEffect(() => {
    coreGetOrderList(1).then((response) => {
      response && setOrderListData(response.data);
      response && setIsLoading(true);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNextPage = useCallback(() => {
    const _orders = store.getState().orderList.orders;

    if (_orders.page >= _orders.pages) return;

    setIsLoading(false);
    setOrdersLoader(true);

    coreGetOrderList(_orders.page + 1).then((response) => {
      setOrdersLoader(false);
      const data = response.data;
      data.items = [..._orders.items, ...data.items];
      setOrderListData(data);
      setIsLoading(true);
    });
  }, [store, coreGetOrderList, setIsLoading, setOrdersLoader]);

  const handleScroll = useCallback(() => {
    let currentIsOrders = "";

    setIsLoading((prevIsOrders) => {
      if (prevIsOrders) {
        currentIsOrders = prevIsOrders;
      }

      return prevIsOrders;
    });

    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 && currentIsOrders) {
      handleNextPage();
    }
  }, [handleNextPage, isLoading, setIsLoading]);

  if (!orders || !userData) return <Loader atCenter={true} />;

  return (
    <Container className={"mt-3 mb-5"}>
      <Row>
        <Col xs={12}>
          <ProfileCard userData={userData} />
        </Col>
      </Row>
      <Row>
        {orders.items
          ? orders.items.map((order, idx) => (
              <Col xs={12} className="mt-3" key={idx}>
                <OrderCard order={order} onClick={() => navigate(`/orders/${order.id}`)} />
              </Col>
            ))
          : null}
      </Row>

      {ordersLoader ? <Loader /> : null}
    </Container>
  );
};

export default OrderListPage;
