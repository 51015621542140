import React, { useEffect, useState } from "react";

import {
  useCheckoutSelector,
  useRestaurantSelector,
  useUserSelector,
} from "../../store/useSelectors";
import ProgressBottomSheet from "./ProgressBottomSheet";
import ProgressIndicatorIcon from "./ProgressIndicatorIcon";
import { calculateProgress } from "../../utils/calculateProgress";
import { telegramSendSoftTaptic } from "../../services/telegram";
import { TDeliveryType } from "../../data/consts";

const ProgressIndicator = () => {
  const [open, setOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const { deliveryAddress, deliveryType } = useCheckoutSelector();
  const { cart } = useUserSelector();
  const { settings } = useRestaurantSelector();

  useEffect(() => {
    if (deliveryAddress) {
      if (deliveryAddress.delivery_area.minimal_cost) {
        setIsActive(true);
      }
      if (cart.total >= deliveryAddress.delivery_area.minimal_cost) {
        setIsActive(false);
      }

      if (cart.total && deliveryAddress.delivery_area.minimal_cost) {
        setProgressValue(calculateProgress(cart.total, deliveryAddress.delivery_area.minimal_cost));
      } else {
        setIsActive(false);
      }
    }
  }, [cart.total]);

  if (!deliveryAddress || !settings || TDeliveryType.DELIVERY !== deliveryType) {
    return;
  }

  return (
    <>
      {isActive && (
        <div
          onClick={() => {
            telegramSendSoftTaptic();
            setOpen(!open);
          }}
        >
          <ProgressIndicatorIcon
            progressValue={progressValue}
            min={deliveryAddress.delivery_area.minimal_cost - cart.total}
          />
        </div>
      )}
      <ProgressBottomSheet
        open={open}
        setOpen={setOpen}
        progressValue={progressValue}
        min={deliveryAddress.delivery_area.minimal_cost - cart.total}
        minOrder={deliveryAddress.delivery_area.minimal_cost}
        cost={deliveryAddress?.delivery_area?.cost}
      />
    </>
  );
};

export default ProgressIndicator;
