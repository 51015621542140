import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import UISlice from "./slices/ui";
import RestaurantSlice from "./slices/restaurant";
import UserSlice from "./slices/user";
import CheckoutSlice from "./slices/checkout";
import OrderSlice from "./slices/order";
import OrderListSlice from "./slices/orderList";
import CacheSlice from "./slices/cache";

export const useUIActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(UISlice.actions, dispatch);
};

export const useRestaurantActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(RestaurantSlice.actions, dispatch);
};

export const useUserActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(UserSlice.actions, dispatch);
};

export const useCheckoutActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(CheckoutSlice.actions, dispatch);
};

export const useOrderActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(OrderSlice.actions, dispatch);
};

export const useOrderListActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(OrderListSlice.actions, dispatch);
};

export const useCacheActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(CacheSlice.actions, dispatch);
};
