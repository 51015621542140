import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Copyright from "../Copyright";
import MainButton from "../MainButton";
import { coreGetCart } from "../../api/core";
import { useUserActions } from "../../store/useActions";
import { useCheckoutSelector, useUserSelector } from "../../store/useSelectors";
import CartItemList from "./CartItemList";
import PopularProductList from "../PopularProductList/PopularProductList";
import ProgressIndicatorIcon from "../ProgressIndicator/ProgressIndicatorIcon";
import { calculateProgress } from "../../utils/calculateProgress";
import { TDeliveryType } from "../../data/consts";

const Cart = () => {
  const [progressValue, setProgressValue] = useState(0);
  const { deliveryAddress, deliveryType } = useCheckoutSelector();
  const { setCart } = useUserActions();
  const { cart } = useUserSelector();

  useEffect(() => {
    coreGetCart()
      .then((response) => {
        setCart(response.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (deliveryAddress) {
      setProgressValue(calculateProgress(cart.total, deliveryAddress.delivery_area.minimal_cost));
    }
  }, [cart.total]);

  const isProgressIndicatorIcon =
    deliveryAddress &&
    deliveryAddress.delivery_area.minimal_cost > cart.total &&
    cart.total > 0 &&
    deliveryType === TDeliveryType.DELIVERY;

  if (!cart) return null;

  return cart.items ? (
    <>
      <Container fluid>
        <CartItemList cartItems={cart.items} />
      </Container>
      {isProgressIndicatorIcon && (
        <div style={{ marginLeft: "10px" }} className="ml-2">
          <ProgressIndicatorIcon
            progressValue={progressValue}
            type={"static"}
            min={deliveryAddress.delivery_area.minimal_cost - cart.total}
          />
        </div>
      )}
      {<PopularProductList title={"Добавить ещё"} className={"mb-3 mt-3"} />}
      <Copyright />
      <MainButton text={"Заказать — " + cart.total + " ₽"} redirectTo="/checkout" />
    </>
  ) : null;
};

export default Cart;
