export const ArrowIcon = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="#00990000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13L11 8L6 3"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
