import { BASE_RESTAURANT_ID, BASE_TOKEN } from "../assets/const";

export const getRestaurantIdParam = () => {
  const urlParamsRestId = new URLSearchParams(window.location.search).get("restaurant_id");
  if (urlParamsRestId) {
    window.localStorage.setItem("restaurantId", urlParamsRestId);
    return urlParamsRestId;
  }

  return window.localStorage.getItem("restaurantId") || BASE_RESTAURANT_ID;
};

export const getTokenParam = () => {
  return new URLSearchParams(window.location.search).get("token") || BASE_TOKEN;
};
