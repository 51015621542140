import React, { useEffect, useRef } from "react";
import { telegramSendSoftTaptic } from "../../services/telegram";

const ProductPageRadioGroup = ({
  props,
  setPrice,
  price,
  currentOptions,
  setCurrentOptions,
  requiredOptions,
  setActiveRequiredOptions,
  activeRequiredOptions,
  errorOptions,
  scrollToItem,
  setScrollToItem,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(0);
  const [selectedPrice, setSelectedPrice] = React.useState(0);
  const [errorStyle, setErrorStyle] = React.useState(false);
  const myRef = useRef();

  useEffect(() => {
    if (scrollToItem === props.id) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollToItem();
    }
  }, [scrollToItem]);

  useEffect(() => {
    for (let i = 0; i < props.options.length; i++) {
      let option = props.options[i];
      if (option.price === 0) {
        setSelectedOption(option.id);
        const activeIndex = activeRequiredOptions.indexOf(props.id);
        if (!currentOptions.includes(option.id)) {
          setCurrentOptions([...currentOptions, Number(option.id)]);
        }
        if (activeIndex === -1) {
          setActiveRequiredOptions((prevOptions) => {
            return [...prevOptions, props.id];
          });
        }
        break;
      }
    }
  }, [requiredOptions]);

  useEffect(() => {
    if (errorOptions.indexOf(props.id) !== -1) {
      setErrorStyle(true);
    } else {
      setErrorStyle(false);
    }
  }, [errorOptions]);

  const handleOptionChange = (id) => {
    telegramSendSoftTaptic();

    const currentAll = currentOptions.filter((el) => el !== Number(selectedOption));
    setCurrentOptions([...currentAll, Number(id.target.id)]);
    setSelectedOption(id.target.id);

    const currentPrice = Number(price) - Number(selectedPrice);
    setPrice(Number(currentPrice) + Number(id.target.value));
    setSelectedPrice(id.target.value);

    const activeIndex = activeRequiredOptions.indexOf(props.id);
    if (activeIndex === -1) {
      setActiveRequiredOptions((prevOptions) => {
        return [...prevOptions, props.id];
      });
    }
  };

  return (
    <div ref={myRef} className="product-page__group">
      <div className={"mb-3"}>
        <h5
          className={
            errorStyle
              ? ["prooduct-page__error", "product-page__title-group"].join(" ")
              : "product-page__title-group"
          }
        >
          {props.title}
        </h5>
        <p className="product-page__group-label">Необходимо выбрать что-то одно</p>
      </div>
      {props.options.map((item) => (
        <div key={item.id} className="product-page__checkbox-block">
          <label className="d-flex justify-content-between align-items-center" htmlFor={item.id}>
            <div className="d-flex align-items-center product-page__text">
              <p style={{ marginBottom: 0 }}>{item.title}</p>
              {item.price > 0 && <span>+ {item.price} ₽</span>}
            </div>
            <div className="product-page__dashed"></div>
            <input
              className="product-page__checkbox"
              type="radio"
              id={item.id}
              value={item.price}
              checked={selectedOption == item.id}
              onChange={handleOptionChange}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

export default ProductPageRadioGroup;
