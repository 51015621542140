import flamy11LottieAnimationData from "../assets/lottie/sticker_flamy_11.json";
import eggAndBeconLottieAnimationData from "../assets/lottie/sticker_egg_and_becon.json";
import eggRunnigLottieAnimationData from "../assets/lottie/sticker_egg_running.json";
import utyaDuckWinnerLottieAnimationData from "../assets/lottie/utya_duck/winner.json";
import babyYodaSadLottieAnimationData from "../assets/lottie/sticker_baby_yoda_sad.json";

export const TDeliveryType = {
  DELIVERY: "DELIVERY",
  TABLE: "TABLE",
  PICKUP: "PICKUP",
};

export const TOrderState = {
  CREATED: "CREATED",
  COOKING: "COOKING",
  DELIVERING: "DELIVERING",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const TPaymentMethod = {
  CASH: "CASH",
  CARD: "CARD",
  ONLINE: "ONLINE",
};

export const stateToAnimationDataMap = {
  [TOrderState.CREATED]: flamy11LottieAnimationData,
  [TOrderState.COOKING]: eggAndBeconLottieAnimationData,
  [TOrderState.DELIVERING]: eggRunnigLottieAnimationData,
  [TOrderState.FINISHED]: utyaDuckWinnerLottieAnimationData,
  [TOrderState.CANCELLED]: babyYodaSadLottieAnimationData,
};

export const stateToTextMap = {
  [TOrderState.CREATED]: "Заказ создан",
  [TOrderState.COOKING]: "Готовится...",
  [TOrderState.DELIVERING]: "Доставляется...",
  [TOrderState.FINISHED]: "Заказ доставлен",
  [TOrderState.CANCELLED]: "Заказ отменён",
};

export const stateToBadgeColorMap = {
  [TOrderState.CREATED]: "info",
  [TOrderState.COOKING]: "warning",
  [TOrderState.DELIVERING]: "warning",
  [TOrderState.FINISHED]: "success",
  [TOrderState.CANCELLED]: "danger",
};

export const paymentMethodMap = {
  [TPaymentMethod.CASH]: "💸 Оплата наличными",
  [TPaymentMethod.CARD]: "💳 Оплата по карте",
  [TPaymentMethod.ONLINE]: "🌐 Онлайн-оплата",
};

export const deliveryTypeMap = {
  [TDeliveryType.PICKUP]: "Заберу сам",
  [TDeliveryType.DELIVERY]: "Доставка",
  [TDeliveryType.TABLE]: "К столику",
};

export const TCoreErrorCode = {
  RESTAURANT_USER_BLOCKED: "RESTAURANT_USER_BLOCKED",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  RESTAURANT_USER_NOT_FOUND: "RESTAURANT_USER_NOT_FOUND",
  RESTAURANT_NOT_FOUND: "RESTAURANT_NOT_FOUND",
};
