import React, { useEffect, useState } from "react";

import styles from "./DeliveryAddress.module.scss";
import { Container } from "reactstrap";
import { useCheckoutSelector, useRestaurantSelector } from "../../store/useSelectors";
import { TDeliveryType } from "../../data/consts";
import Loader from "../Loader";
import { Link, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../ui/icons/ArrowIcon";
import { CourierIcon } from "../ui/icons/CourierIcon";
import { ProductIcon } from "../ui/icons/ProductIcon";
import { useCheckoutActions } from "../../store/useActions";
import { telegramSelectionChanged, telegramSendSoftTaptic } from "../../services/telegram";

const DeliveryAddress = ({ isChoose }) => {
  const [isPopupDelivery, setIsPopupDelivery] = useState(false);
  const { deliveryAddress, deliveryType } = useCheckoutSelector();
  const { setDeliveryType } = useCheckoutActions();
  const { settings, title } = useRestaurantSelector();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings.delivery_types && !deliveryType) {
      if (
        settings.delivery_types.includes(TDeliveryType.DELIVERY) &&
        !(
          settings.delivery_types.includes(TDeliveryType.TABLE) ||
          settings.delivery_types.includes(TDeliveryType.PICKUP)
        )
      ) {
        setDeliveryType(settings.delivery_types(TDeliveryType.DELIVERY));
      } else {
        setDeliveryType(
          (settings.delivery_types.includes(TDeliveryType.PICKUP) && TDeliveryType.PICKUP) ||
            (settings.delivery_types.includes(TDeliveryType.TABLE) && TDeliveryType.TABLE),
        );
      }
    }
  }, [settings.delivery_types]);

  const onSwitch = (value) => {
    telegramSelectionChanged();

    if (settings.delivery_types.indexOf(TDeliveryType.DELIVERY) !== value && isChoose) {
      setDeliveryType(value);
      navigate("/");
    }
    setDeliveryType(value);
  };

  const onOpenPopupDelivery = () => {
    telegramSendSoftTaptic();
    setIsPopupDelivery(!isPopupDelivery);
  };

  if (!settings.delivery_types) {
    return <Loader />;
  }

  const isDelivery = settings.delivery_types.includes(TDeliveryType.DELIVERY);
  const isTable =
    settings.delivery_types.includes(TDeliveryType.TABLE) ||
    settings.delivery_types.includes(TDeliveryType.PICKUP);

  const isPositionMarker = isDelivery && isTable ? deliveryType === TDeliveryType.DELIVERY : true;

  const TypeStyles =
    isDelivery && isTable
      ? [styles.address__item, styles.active]
      : [styles.address__item, styles.active, styles.all];

  return (
    <>
      <Container fluid className="mb-4">
        <div className={styles.address}>
          {isDelivery && (
            <div
              style={
                isDelivery && isTable
                  ? { flex: "0 0 50%" }
                  : { flex: "0 0 100%", pointerEvents: "none" }
              }
              onClick={() => onSwitch(TDeliveryType.DELIVERY)}
              className={
                deliveryType === TDeliveryType.DELIVERY
                  ? TypeStyles.join(" ")
                  : styles.address__item
              }
            >
              Доставка
            </div>
          )}
          {isTable && (
            <div
              style={
                isDelivery && isTable
                  ? { flex: "0 0 50%" }
                  : { flex: "0 0 100%", pointerEvents: "none" }
              }
              onClick={() =>
                onSwitch(
                  (settings.delivery_types.includes(TDeliveryType.PICKUP) &&
                    TDeliveryType.PICKUP) ||
                    (settings.delivery_types.includes(TDeliveryType.TABLE) && TDeliveryType.TABLE),
                )
              }
              className={
                deliveryType === TDeliveryType.PICKUP || deliveryType === TDeliveryType.TABLE
                  ? TypeStyles.join(" ")
                  : styles.address__item
              }
            >
              Самовывоз
            </div>
          )}
          <div
            style={isDelivery && isTable ? { width: "50%" } : { width: "100%" }}
            className={
              isPositionMarker
                ? styles.address__marker
                : [styles.address__marker, styles.address__marker_pickup].join(" ")
            }
          ></div>
        </div>
        {deliveryType === TDeliveryType.DELIVERY && isDelivery ? (
          <div className={styles.address__current}>
            {deliveryAddress?.text ? (
              <Link
                to={"/delivery-address/choose"}
                className={styles.address__option}
                onClick={() => telegramSendSoftTaptic()}
              >
                <CourierIcon color="var(--text-color)" />
                <div className={styles.address__text}>
                  <div className={styles.address__help_text}>Доставка</div>
                  <p>{deliveryAddress.text}</p>
                </div>
                <ArrowIcon className={styles.address__arrow} color={"var(--text-color)"} />
              </Link>
            ) : (
              <Link
                to={"/delivery-address/choose"}
                onClick={onOpenPopupDelivery}
                className={styles.address__option}
              >
                <CourierIcon color="var(--text-color)" />
                <p>Указать адрес доставки</p>
                <ArrowIcon className={styles.address__arrow} color={"var(--text-color)"} />
              </Link>
            )}
          </div>
        ) : (
          <div className={styles.address__current}>
            <div className={styles.address__option}>
              <ProductIcon color="var(--text-color)" />
              <div className={styles.address__text}>
                <div className={styles.address__help_text}>Самовывоз</div>
                <p>{title}</p>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default DeliveryAddress;
