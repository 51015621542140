import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { coreGetOrder } from "../api/core";
import { useOrderActions } from "../store/useActions";
import { useOrderSelector, useRestaurantSelector } from "../store/useSelectors";
import Loader from "../components/Loader";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Lottie from "lottie-react";
import { formatPhoneNumber, getFormattedBonuses } from "../services/utils";
import {
  deliveryTypeMap,
  paymentMethodMap,
  stateToAnimationDataMap,
  stateToTextMap,
  TPaymentMethod,
} from "../data/consts";
import OrderItemCard from "../components/OrderItemCard/OrderItemCard";

const OrderPage = () => {
  const { id } = useParams();
  const { setOrderData } = useOrderActions();
  const { data } = useOrderSelector();
  const { settings } = useRestaurantSelector();
  const navigate = useNavigate();

  useEffect(() => {
    coreGetOrder({ id: id }).then((response) => response && setOrderData(response.data));
  }, []);

  if (!data || data.id.toString() !== id) return <Loader atCenter={true} />;

  return (
    <Container className={"mt-3 mb-5"}>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} className="justify-content-center d-flex">
                  <Lottie className="w-50" animationData={stateToAnimationDataMap[data.state]} />
                </Col>
                <Col xs={12} className="text-center mt-3">
                  <h3>{stateToTextMap[data.state]}</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {
        // Если не оплачен
        data.payment_method === TPaymentMethod.ONLINE && !data.payed_at ? (
          <Row className={"mt-4"}>
            <Col xs={12}>
              <Card
                style={{ backgroundColor: "var(--danger-color)" }}
                onClick={() => navigate(`/orders/${id}/pay`)}
              >
                <CardBody>
                  <CardTitle>
                    <h4>⚠️ Заказ не оплачен</h4>
                  </CardTitle>
                  <Col xs={12}>Нажмите, чтобы оплатить заказ</Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null
      }

      <h4 className={"mt-4"}>Товары</h4>
      <Row className={"mb-5"}>
        {data.items.map((item) => (
          <Col xs={12} className={" cart__border"}>
            <OrderItemCard item={item} />
          </Col>
        ))}
      </Row>

      <Row>
        {settings.bonus_enabled && (data.bonuses_spent || data.bonuses_received) ? (
          <Col xs={12} className={"mb-3"}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h4>🎉 Бонусы</h4>
                </CardTitle>
                <Row className={"mt-2 mb-2"}>
                  {data.bonuses_spent ? (
                    <Col xs={12}>
                      <div>Использовано: {getFormattedBonuses(data.bonuses_spent)}</div>
                    </Col>
                  ) : null}
                  {data.bonuses_received ? (
                    <Col xs={12}>
                      <div>Получено: {getFormattedBonuses(data.bonuses_received)}</div>
                    </Col>
                  ) : null}
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : null}
        <Col xs={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Информация</h4>
              </CardTitle>
              {
                data.restaurant_promocode ? (
                    <Col xs={12}>
                <div>
                  Промокод: {data.restaurant_promocode.code}
                </div>
              </Col>
                ) : null
              }
              <Col xs={12}>
                <div>
                  Сумма заказа: {data.cost} ₽
                  {data.cost < data.pretotal_cost ? (
                    <s style={{ marginLeft: "5px" }}>{data.pretotal_cost} ₽</s>
                  ) : null}
                </div>
              </Col>

              <Col xs={12}>
                <div>Номер телефона: {formatPhoneNumber(data.phone_number)}</div>
              </Col>
              {data.comment ? (
                <Col xs={12}>
                  <div>Комментарий: {data.comment}</div>
                </Col>
              ) : null}

              <Col xs={12} className={"mt-2"}>
                <div className="d-flex opacity-50">
                  {deliveryTypeMap[data.delivery_type]} · {paymentMethodMap[data.payment_method]}
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderPage;
