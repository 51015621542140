import { setCssProperty } from "./css";
import eruda from "eruda";
import { setThemeColors } from "./theme";
import { useRestaurantSelector } from "../store/useSelectors";
import { store } from "../store/store";
import {
  restaurantLocalStorageGetItem,
  restaurantLocalStorageSetItem,
} from "../utils/restaurantLocalStorage";

export const webApp = window.Telegram.WebApp;

export const telegramIsRunning = () => !!webApp.backgroundColor;

export const telegramInitialize = () => {
  webApp.ready();
  webApp.enableClosingConfirmation();
  if (!webApp.isExpanded) webApp.expand();

  // TODO: выпилить или сделать только в dev-mode
  webApp.SettingsButton.show();
  webApp.SettingsButton.onClick(() => eruda.destroy());

  webApp.offEvent("themeChanged", themeChanged);
  // Убрано, так как при смене телеграмовские цвета заменяют settings.colors
  webApp.onEvent("themeChanged", themeChanged);

  telegramSetupTheme({});
};

const themeChanged = () => {
  telegramSetupTheme({ fetchFromLocalStorage: true });
};

export const telegramSetupTheme = ({ fetchFromLocalStorage }) => {
  if (Object.keys(webApp.themeParams).length !== 0) {
    setCssProperty("--color-scheme", webApp.themeParams.colorScheme);
    document.body.dataset.colorScheme = webApp.colorScheme;

    if (webApp.colorScheme === "light") {
      webApp.setBackgroundColor(webApp.themeParams.secondary_bg_color);
      webApp.setHeaderColor(webApp.themeParams.secondary_bg_color);
      setCssProperty("--bg-color", webApp.themeParams.secondary_bg_color);
      setCssProperty("--secondary-bg-color", webApp.themeParams.bg_color);
    } else {
      webApp.setBackgroundColor(webApp.themeParams.bg_color);
      webApp.setHeaderColor(webApp.themeParams.bg_color);
      setCssProperty("--bg-color", webApp.themeParams.bg_color);
      setCssProperty("--secondary-bg-color", webApp.themeParams.secondary_bg_color);
    }
    setCssProperty("--text-color", webApp.themeParams.text_color);
    setCssProperty("--hint-color", webApp.themeParams.hint_color);
    setCssProperty("--button-color", webApp.themeParams.button_color);
    setCssProperty("--button-text-color", webApp.themeParams.button_text_color);
    setCssProperty("--destructive-text-color ", webApp.themeParams.destructive_text_color);
  }

  if (fetchFromLocalStorage) {
    // Костыль, так как при event themeChanged слетают
    setThemeColors(JSON.parse(restaurantLocalStorageGetItem("colors")));
  }
};

export const telegramNotificationOccurredSuccess = () =>
  webApp.HapticFeedback.notificationOccurred("success");

export const telegramSendSoftTaptic = () => webApp.HapticFeedback.impactOccurred("soft");

export const telegramSelectionChanged = () => webApp.HapticFeedback.selectionChanged(() => {});

export const telegramOpenLink = (url) => webApp.openLink(url);

export const telegramShowAlert = (alertMessage, callback) =>
  telegramIsRunning() && webApp.showAlert(alertMessage, callback);

// Работает только внутри Телеги
export const telegramOpenInvoice = (url, callback) => webApp.openInvoice(url, callback);
