import React from "react";
const ProductCardDiscountBadge = () => {
  return (
    <>
      <svg
        className="product-card__badge"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" rx="10" fill="var(--button-color)" />
        <path
          d="M20.625 9.375L9.375 20.625"
          stroke="var(--button-text-color)"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9375 13.125C12.1456 13.125 13.125 12.1456 13.125 10.9375C13.125 9.72938 12.1456 8.75 10.9375 8.75C9.72938 8.75 8.75 9.72938 8.75 10.9375C8.75 12.1456 9.72938 13.125 10.9375 13.125Z"
          stroke="var(--button-text-color)"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0625 21.25C20.2706 21.25 21.25 20.2706 21.25 19.0625C21.25 17.8544 20.2706 16.875 19.0625 16.875C17.8544 16.875 16.875 17.8544 16.875 19.0625C16.875 20.2706 17.8544 21.25 19.0625 21.25Z"
          stroke="var(--button-text-color)"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ProductCardDiscountBadge;
