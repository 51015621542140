import React, { useEffect } from "react";
import { useRestaurantSelector, useUserSelector } from "../store/useSelectors";
import { useUIActions, useUserActions } from "../store/useActions";
import { Container, Row } from "reactstrap";

import CategoriesNavigation from "./CategoriesNavigation";
import ProductCard from "./ProductCard/ProductCard";
import { coreGetCart } from "../api/core";
import Copyright from "./Copyright";
import MainButton from "./MainButton";
import { ProductMenuSceleton } from "./Sceletons";

const ProductMenu = () => {
  const { categories, isLoaded } = useRestaurantSelector();
  const { setCurrentCategory } = useUIActions();
  const { cart, token } = useUserSelector();
  const { setCart, setIsLoading } = useUserActions();

  useEffect(() => {
    // Запрос продуктов с API
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!token) return;
    if (Object.keys(cart).length === 0) {
      coreGetCart()
        .then((response) => {
          setCart(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const c = document.querySelectorAll("div#product-category");
    const scrollEventListener = (event) => {
      const pos = window.scrollY + window.screen.height * 0.3;

      c.forEach((element) => {
        const bounds = element.getBoundingClientRect();
        const y1 = element.offsetTop;
        const y2 = y1 + bounds.height;
        if (y1 < pos && y2 > pos) {
          setCurrentCategory(element.dataset.categoryId);
        }
      });
    };
    window.addEventListener("scroll", scrollEventListener);

    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, [categories, isLoaded]);

  return (
    <>
      <CategoriesNavigation loaded={isLoaded} />
      <Container fluid>
        {!isLoaded ? (
          <ProductMenuSceleton />
        ) : (
          categories.map((category, idx) => {
            return (
              <div id="product-category" data-category-id={category.id} key={idx}>
                <div className="content__title">{category.title}</div>
                <div className="content__items">
                  <Row className="g-3">
                    {category.products.map((obj) => (
                      <ProductCard product={obj} key={obj.id} />
                    ))}
                  </Row>
                </div>
              </div>
            );
          })
        )}
        <Copyright />
      </Container>
      {cart.total ? (
        <MainButton text={"Оформить — " + cart.total + " ₽"} redirectTo="/cart" />
      ) : null}
    </>
  );
};
export default ProductMenu;
