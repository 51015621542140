import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { getPhotoThumbnail } from "../../services/media";
import ImageWithLoader from "../ImageWithLoader";
import { telegramNotificationOccurredSuccess } from "../../services/telegram";
import { coreSetCartItemQuantity } from "../../api/core";
import { useUserActions } from "../../store/useActions";

import styles from "./Cart.module.scss";
import { sortOptions } from "../../utils/sortOptions";
import { MoneyFormat } from "../../services/utils";

const CartItem = ({ item, ...props }) => {
  const { setCart } = useUserActions();
  const [isLoading, setIsLoading] = useState(false);

  const onClickMinus = () => {
    setIsLoading(true);
    telegramNotificationOccurredSuccess();
    coreSetCartItemQuantity(item.id, item.quantity - 1).then((response) => {
      setCart(response.data);
      setIsLoading(false);
    });
  };

  const onClickPlus = () => {
    setIsLoading(true);
    telegramNotificationOccurredSuccess();
    coreSetCartItemQuantity(item.id, item.quantity + 1).then((response) => {
      setCart(response.data);
      setIsLoading(false);
    });
  };

  return (
    <div className={styles.cartitem} {...props}>
      <div className="cart__body">
        <Row className="gap-2">
          <Col xs={2} className="p-0">
            <ImageWithLoader
              className="cart-item-img"
              src={getPhotoThumbnail(item.product.photo)}
              loaderStyle={{ height: "10vh" }}
              hideLoaderText
            />
          </Col>
          <Col style={{ marginLeft: "8px" }}>
            <Row>
              <Col xs={12} className={"mb-2 p-0"}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={7}>
                    <div className={styles.cartitem__title}>{item.product.title}</div>
                  </Col>
                  {item.promocode_price === null ? (
                    <Col xs={5} className="d-flex justify-content-end pr-2">
                      <div className={styles.cartitem__counter}>
                        <button
                          className={isLoading ? styles.opacity : ""}
                          onClick={() => !isLoading && onClickMinus()}
                        >
                          <svg
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 8h5H3"
                              stroke="#ffffff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                        <input type="text" readOnly value={item.quantity} />
                        <button
                          className={isLoading ? styles.opacity : ""}
                          onClick={() => !isLoading && onClickPlus()}
                        >
                          <svg
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 8V3m0 5v5m0-5h5M8 8H3"
                              stroke="#ffffff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>

              {item?.options?.length > 0 && (
                <Col
                  xs={12}
                  className={`p-0 mb-3 ${styles.negative_margin}`}
                  style={{ color: "var(--hint-color)" }}
                >
                  {sortOptions(item.options).map((optionGroup, idx) => {
                    return (
                      <div className="mb-1" key={idx}>
                        <p style={{ fontSize: "0.9rem" }} className="mb-0">
                          {optionGroup.optionGroupTitle}:
                        </p>
                        <ul className="m-0" style={{ paddingLeft: "1rem", listStyle: "disc" }}>
                          {optionGroup.options.map((option, idx) => (
                            <li
                              key={idx}
                              className="d-flex align-items-center cart-item__add text-break"
                            >
                              <p style={{ marginBottom: 0 }}>- {option.title}</p>
                              {option.price ? (
                                <span>: {MoneyFormat.format(option.price)}</span>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </Col>
              )}

              <Col xs={12} className={`p-0 ${styles.negative_margin}`}>
                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <Row className="d-flex align-items-center">
                    <Col className="col-auto">
                      <div className={"h6 mb-0"} style={{ alignItems: "center" }}>
                        {item.total} ₽
                      </div>
                    </Col>
                    {item.total < item.pre_total ? (
                      <Col className="col-auto p-0">
                        <span className="h6 weight" style={{ color: "var(--hint-color)" }}>
                          <strike>{item.pre_total} ₽</strike>
                        </span>
                      </Col>
                    ) : null}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CartItem;
