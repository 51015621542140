export const getCenterOfPolygon = (arr) => {
  var minX, maxX, minY, maxY;
  for (var i = 0; i < arr.length; i++) {
    minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
    maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
    minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
    maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
  }
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};
