import React from "react";
import { telegramSendSoftTaptic } from "../../services/telegram";

const ProductPageCheckbox = ({ props, onChangeActiveCheckbox, activeCheckbox, max }) => {
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    telegramSendSoftTaptic();
    if (event.target.checked === true && activeCheckbox.length === max) return;
    setChecked(event.target.checked);
    onChangeActiveCheckbox(props.id, event.target.checked, props.price);
  };

  return (
    <div className="product-page__checkbox-block">
      <label className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center product-page__text">
          <p
            className={
              activeCheckbox.length === max && checked === false
                ? ["product__opacity"].join(" ")
                : "pro"
            }
            style={{ marginBottom: 0 }}
          >
            {props.title}
          </p>
          {props.price ? (
            <span
              className={
                activeCheckbox.length === max && checked === false
                  ? ["product__opacity"].join(" ")
                  : "pro"
              }
            >
              + {props.price} ₽
            </span>
          ) : null}
        </div>
        <div className="product-page__dashed"></div>
        <input
          className={
            activeCheckbox.length === max && checked === false
              ? ["product-page__checkbox", "product__opacity"].join(" ")
              : "product-page__checkbox"
          }
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
      </label>
    </div>
  );
};

export default ProductPageCheckbox;
