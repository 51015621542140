import { coreGetRestaurantUserDeliveryAddresses } from "../../api/core";
import CacheSlice from "../../store/slices/cache";
import { store } from "../../store/store";

export const fetchDeliveryAddresses = async (force) => {
  const { setUserDeliveryAddresses } = CacheSlice.actions;
  const initialState = store.getState();
  const userDeliveryAddresses = initialState.cache.userDeliveryAddresses;

  if (userDeliveryAddresses === null || force) {
    const response = await coreGetRestaurantUserDeliveryAddresses();
    store.dispatch(setUserDeliveryAddresses(response.data));
  }
};
