import React from "react";
import { Col, Row } from "reactstrap";
import chatupperLogo from "../assets/chatupper.png";
import { useRestaurantSelector } from "../store/useSelectors";

export const LogoLoader = ({ className, hideLoaderText, ...props }) => {
  const classes =
    "d-flex align-items-center justify-content-center text-center logo-loader " + (className || "");
  return (
    <Row className={classes} {...props}>
      <Col xs={12}>
        <img src={chatupperLogo} />
      </Col>
      {hideLoaderText ? null : (
        <Col xs={12}>
          <div className={"h6"}>Кафеботум</div>
        </Col>
      )}
    </Row>
  );
};

export const BaseLoader = (props) => (
  <div className="lds-ring" {...props}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const Loader = ({ atCenter, loaderStyle, hideLoaderText, ...props }) => {
  const { tariff } = useRestaurantSelector();
  const isBaseLoader = tariff.options.BASE_LOADER;

  let loader = null;
  const styles = {};

  if (!isBaseLoader) {
    loader = <LogoLoader style={loaderStyle} hideLoaderText={hideLoaderText} />;
  } else {
    loader = <BaseLoader style={loaderStyle} />;
  }

  if (atCenter) {
    styles.marginTop = "50%";
  }

  return (
    <div style={styles} {...props}>
      {loader}
    </div>
  );
};

export default Loader;
