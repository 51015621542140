import { Col, Row } from "reactstrap";
import React from "react";

export const ColSceleton = (props) => (
  <Col {...props}>
    <div className={"product-card-skeleton shimmer h-100"}></div>
  </Col>
);

export const ProductCardSkeleton = () => <ColSceleton xs={6} md={4} lg={2} />;

export const ProductMenuSceleton = () => {
  return (
    <>
      {[...new Array(3)].map((_, index) => (
        <React.Fragment key={index}>
          <div className="content__title-skeleton"></div>
          <div id="product-category" data-category-id="0">
            <div className="content__items">
              <Row className="g-3">
                {[...new Array(6)].map((_, index) => (
                  <ProductCardSkeleton key={index} />
                ))}
              </Row>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
