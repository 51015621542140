import React from "react";
import ProductMenu from "../components/ProductMenu";
import PopularProductList from "../components/PopularProductList/PopularProductList";
import DeliveryAddress from "../components/DeliveryAddress/DeliveryAddress";
import ProgressIndicator from "../components/ProgressIndicator/ProgressIndicator";

const MenuPage = (props) => {
  return (
    <>
      <ProgressIndicator />
      <DeliveryAddress />
      <PopularProductList title={"🔥 Рекомендуем"} className={"mb-3"} />
      <ProductMenu {...props} />
    </>
  );
};

export default MenuPage;
