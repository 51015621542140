import { createSlice } from "@reduxjs/toolkit";

const UISlice = createSlice({
  name: "ui",
  initialState: {
    currentCategory: null,
    categoriesRefs: null,
    hideBackButton: false,
  },
  reducers: {
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCategoriesRefs: (state, action) => {
      state.categoriesRefs = action.payload;
    },
    setHideBackButton: (state, action) => {
      state.hideBackButton = action.payload;
    },
  },
});

export default UISlice;
