import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useRestaurantSelector, useUserSelector } from "../../store/useSelectors";

import styles from "./PopularProductList.module.scss";
import SmallProductCard from "./SmallProductCard";

const PopularProductList = ({ title, ...props }) => {
  const [popularItems, setPopularItems] = useState([]);
  const [isItems, setIsItems] = useState(true);
  const { cart } = useUserSelector();
  const { featuredProducts } = useRestaurantSelector();

  useEffect(() => {
    if (featuredProducts && cart.updated_at) {
      setPopularItems([]);

      let currentItems = [];

      if (cart?.items?.length && featuredProducts.length) {
        currentItems = featuredProducts.filter((recItem) => {
          const currentId = recItem.id;
          return cart.items.every((cartItem) => currentId !== cartItem?.product?.id);
        });
      } else {
        currentItems = featuredProducts;
      }

      if (currentItems.length === 0) {
        setIsItems(false);
      } else {
        setIsItems(true);
      }

      setPopularItems(currentItems);
    }
  }, [cart.items, featuredProducts]);

  return (
    <>
      {isItems && (
        <Container fluid {...props}>
          <div className="content__title m-0">
            {popularItems.length > 0 ? title : <PopulartTitleSceleton />}
          </div>
          <div className={styles.PopularProduct__items}>
            {popularItems.length > 0
              ? popularItems.map((item) => <SmallProductCard key={item.id} item={item} />)
              : [...new Array(3)].map((_, index) => <PopularItemsSceleton key={index} />)}
          </div>
        </Container>
      )}
    </>
  );
};

export const PopularItemsSceleton = () => (
  <div className={`product-card-skeleton ${styles.PopularProduct__skeleton}`}></div>
);

export const PopulartTitleSceleton = () => (
  <div
    className={`product-card-skeleton ${[
      styles.PopularProduct__skeleton,
      styles.PopularProduct__skeleton_title,
    ].join(" ")}`}
  ></div>
);

export default PopularProductList;
