import React from "react";

import styles from "./CustomCheckBox.module.scss";

const CustomCheckBox = ({ checked, onChangeInput, id }) => {
  const handleChange = (e) => {
    onChangeInput(e.target.checked);
  };

  return (
    <div className={styles.checkbox}>
      <input
        id={id}
        className={[styles.checkbox__input, styles.checkbox__anime].join(" ")}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <label className={styles.checkbox__btn} htmlFor={id}></label>
    </div>
  );
};

export default CustomCheckBox;
