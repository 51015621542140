import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Theme from "./components/Theme";

import "react-spring-bottom-sheet/dist/style.css";
// TODO: Вынести только в dev-mode
import eruda from "eruda";
import ErrorHandler from "./components/ErrorHandler";
import { telegramInitialize } from "./services/telegram";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import { YMInitializer } from "react-yandex-metrika";

// TODO: Вынести только в dev-mode
// eruda.init();

// Инициализация telegram-mini-app
telegramInitialize();

if (window.location.hash) {
  // Убираем параметры, которые передал Telegram, так как они потом уходят в Яндекс Метрику и по ним можно авторизоваться (получить токен),
  // а это уязвимость
  window.location.hash = "";
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <WebAppProvider options={{ smoothButtonsTransition: true }}>
        <Theme>
          <ErrorHandler>
            <App />
          </ErrorHandler>
        </Theme>
      </WebAppProvider>
      <YMInitializer
        accounts={[97216332]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
