import { useSelector } from "react-redux";

export const useUISelector = () => useSelector((state) => state.ui);

export const useRestaurantSelector = () => useSelector((state) => state.restaurant);

export const useUserSelector = () => useSelector((state) => state.user);

export const useCheckoutSelector = () => useSelector((state) => state.checkout);

export const useOrderSelector = () => useSelector((state) => state.order);

export const useOrderListSelector = () => useSelector((state) => state.orderList);

export const useCacheSelector = () => useSelector((state) => state.cache);
