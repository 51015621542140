import React, { useEffect } from "react";
import { useRestaurantSelector, useUserSelector } from "../store/useSelectors";
import ProductPageGroup from "../components/ProductPage/ProductPageGroup";
import { useUserActions } from "../store/useActions";
import { telegramNotificationOccurredSuccess } from "../services/telegram";
import { coreAddCartItem } from "../api/core";
import { Container, Row } from "reactstrap";
import MainButton from "../components/MainButton";
import ProductPageRadioGroup from "../components/ProductPage/ProductPageRadioGroup";
import ProductPageImage from "../components/ProductPage/ProductPageImage";
import { compareByMinSelected } from "../utils/compareByMinSelected";
import { useParams } from "react-router-dom";
import CartItemList from "../components/Cart/CartItemList";

const ProductPage = () => {
  const [product, setProduct] = React.useState([]);
  const [productGroup, setProductGroup] = React.useState([]);
  const [price, setPrice] = React.useState(null);
  const [currentOptions, setCurrentOptions] = React.useState([]);
  const { categories } = useRestaurantSelector();
  const { cart } = useUserSelector();

  const [requiredOptions, setRequiredOptions] = React.useState([]);
  const [activeRequiredOptions, setActiveRequiredOptions] = React.useState([]);
  const [errorOptions, setErrorOptions] = React.useState([]);
  const [scrollToItem, setScrollToItem] = React.useState();

  const { setCart, setIsLoading } = useUserActions();
  const cartContainerRef = React.useRef();

  let { id } = useParams();

  useEffect(() => {
    for (let key in categories) {
      const array = categories[key].products;
      if (array.length > 0) {
        array.forEach((item) => {
          if (item.id === id) {
            const sortedOptionGroups = [...item.option_groups].sort(compareByMinSelected);
            setProductGroup(sortedOptionGroups);
            setProduct(item);
            setPrice(item.sale_price || item.price);
            return null;
          }
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (product.option_groups && requiredOptions.length === 0) {
      product.option_groups.forEach((option) => {
        if (option.min_selected > 0) {
          setRequiredOptions((prevOptions) => {
            return [...prevOptions, option.id];
          });
        }
      });
    }
  }, [product]);

  useEffect(() => {
    if (cartContainerRef.current) {
      cartContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [cartContainerRef]);

  const cartAddItem = (id) => {
    if (activeRequiredOptions.length !== requiredOptions.length) {
      validGroup(true);
      return;
    }
    validGroup(false);
    setIsLoading(true);
    telegramNotificationOccurredSuccess();
    coreAddCartItem(id, 1, currentOptions).then((response) => {
      setCart(response.data);
      setIsLoading(false);
      if (cartContainerRef.current) {
        cartContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  const validGroup = (bool) => {
    const difference = requiredOptions.filter((item) => !activeRequiredOptions.includes(item));
    setErrorOptions(difference);
    if (bool) {
      telegramNotificationOccurredSuccess();
      setScrollToItem(difference[0]);
    }
  };

  const cartItems = cart.items ? cart.items.filter((i) => i.product.id === product.id) : [];

  return (
    <>
      <div className="p-3 product-page">
        <ProductPageImage src={product.photo} />
        <h3 className="product-page__title">{product.title}</h3>
        <p className="product-page__description">{product.description}</p>
        {productGroup &&
          productGroup.map((e) => {
            if (e.max_selected === 1 && e.min_selected === 1) {
              return (
                <ProductPageRadioGroup
                  props={e}
                  key={e.id}
                  setPrice={setPrice}
                  price={price}
                  currentOptions={currentOptions}
                  setCurrentOptions={setCurrentOptions}
                  requiredOptions={requiredOptions}
                  setRequiredOptions={setRequiredOptions}
                  activeRequiredOptions={activeRequiredOptions}
                  setActiveRequiredOptions={setActiveRequiredOptions}
                  errorOptions={errorOptions}
                  scrollToItem={scrollToItem}
                  setScrollToItem={setScrollToItem}
                />
              );
            } else {
              return (
                <ProductPageGroup
                  props={e}
                  key={e.id}
                  setPrice={setPrice}
                  price={price}
                  currentOptions={currentOptions}
                  setCurrentOptions={setCurrentOptions}
                  activeRequiredOptions={activeRequiredOptions}
                  setActiveRequiredOptions={setActiveRequiredOptions}
                  errorOptions={errorOptions}
                  scrollToItem={scrollToItem}
                  setScrollToItem={setScrollToItem}
                />
              );
            }
          })}

        {cart.items && (
          <div ref={cartContainerRef}>
            <Container className={"mt-5"} fluid>
              <Row>
                {cartItems.length > 0 ? (
                  <div style={{ padding: 0 }}>
                    <div>
                      <h4 style={{ padding: 0 }} className="product-page__title-group">
                        В корзине
                      </h4>
                    </div>
                    <CartItemList cartItems={cartItems} />
                  </div>
                ) : null}
              </Row>
            </Container>
          </div>
        )}
      </div>
      {activeRequiredOptions.length === requiredOptions.length ? (
        <MainButton text={"Добавить — " + price + " ₽"} onClick={() => cartAddItem(product.id)} />
      ) : (
        <MainButton text={"Выберите опции"} onClick={() => cartAddItem(product.id)} />
      )}
    </>
  );
};

export default ProductPage;
