import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

import styles from "./ProgressIndicator.module.scss";
import { Container } from "reactstrap";
import ProgressIndicatorIcon from "./ProgressIndicatorIcon";
import { useCheckoutSelector } from "../../store/useSelectors";

const ProgressBottomSheet = ({ open, setOpen, progressValue, min, minOrder, cost }) => {
  const { deliveryAddress } = useCheckoutSelector();

  return (
    <div className={styles.bottomsheet}>
      <BottomSheet
        className={styles.bottomsheet}
        open={open}
        onDismiss={() => setOpen(false)}
        snapPoints={({ maxHeight }) => [0.65 * maxHeight, 0.83 * maxHeight]}
        defaultSnap={({ lastSnap, snapPoints }) => {
          lastSnap ?? Math.max(...snapPoints);
        }}
      >
        <Container fluid>
          <div onClick={() => setOpen(false)} className={styles.bottomsheet__close}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0001 12L18.0001 6M12.0001 12L6.00008 18M12.0001 12L18.0001 18M12.0001 12L6 6"
                stroke="var(--text-color)"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>

          <ProgressIndicatorIcon type={"static"} progressValue={progressValue} min={min} />

          <h4 className={styles.bottomsheet__adress}>
            {deliveryAddress?.text || "Выберите адрес"}
          </h4>
          <div className={styles.bottomsheet__item}>
            <div>Минимальный заказ</div>
            <div>{minOrder} ₽</div>
          </div>
          {cost && (
            <div className={styles.bottomsheet__item}>
              <div>Стоимость доставки</div>
              <div>{cost} ₽</div>
            </div>
          )}
        </Container>
      </BottomSheet>
    </div>
  );
};

export default ProgressBottomSheet;
