import { getFormattedBonuses, MoneyFormat } from "../../services/utils";
import Loader from "../Loader";
import { Card, CardBody } from "reactstrap";

const TotalPricing = ({ settings, cart, cartLoading, ...props }) => {
  if (!cart || cartLoading) return <Loader {...props} />;

  return (
    <div className="total-pricing" {...props}>
      {cart.bonuses_receive ? (
        <Card className={"mb-3"}>
          <CardBody>
            <div className="total-pricing-row">
              <div className="total-pricing-title">🎉 Начислим бонусов</div>
              <div className="total-pricing-value">{getFormattedBonuses(cart.bonuses_receive)}</div>
            </div>
          </CardBody>
        </Card>
      ) : null}

      <div className="total-pricing-row text-muted">
        <div className="total-pricing-title">Стоимость</div>
        <div className="total-pricing-value text-muted">{MoneyFormat.format(cart.pre_total)}</div>
      </div>

      {cart.delivery_cost ? (
        <div className="total-pricing-row text-muted">
          <div className="total-pricing-title">Доставка</div>
          <div className="total-pricing-value text-muted">
            {MoneyFormat.format(cart.delivery_cost)}
          </div>
        </div>
      ) : null}

      {/* Скидки /*/}
      {cart.bonuses_spend ? (
        <div className="total-pricing-row text-muted">
          <div className="total-pricing-title">Оплачено бонусами</div>
          <div className="total-pricing-value text-muted">
            - {getFormattedBonuses(cart.bonuses_spend)}
          </div>
        </div>
      ) : null}

      {cart.sale_total ? (
        <div className="total-pricing-row text-muted">
          <div className="total-pricing-title">Скидка</div>
          <div className="total-pricing-value text-muted">
            - {MoneyFormat.format(cart.sale_total)}
          </div>
        </div>
      ) : null}

      <div className="total-pricing-row">
        <div className="total-pricing-title">Итого</div>
        <div className="total-pricing-value">{MoneyFormat.format(cart.total)}</div>
      </div>
    </div>
  );
};

export default TotalPricing;
