import React, { useEffect } from "react";
import { Col, Spinner } from "reactstrap";
import ProductCardButton from "./ProductCardButton";
import { useUserActions } from "../../store/useActions";
import { coreAddCartItem, coreSetCartItemQuantity } from "../../api/core";
import {
  telegramNotificationOccurredSuccess,
  telegramSendSoftTaptic,
} from "../../services/telegram";
import { useUserSelector } from "../../store/useSelectors";
import ProductCardDiscountBadge from "./ProductCardDiscountBadge";
import ProductCardFeaturedBage from "./ProductCardFeaturedBage";
import ProductCardImage from "./ProductCardImage";
import { Link, useNavigate } from "react-router-dom";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  const { id, title, price, sale_price, photo, description, is_featured } = product;
  const { setCart, setIsLoading } = useUserActions();
  const { cart, isLoading } = useUserSelector();
  const cartItems = cart.items ? cart.items.filter((item) => item.product.id === id) : null;
  const cartItem = cartItems && cartItems.length > 0 ? cartItems[0] : null;

  const [isLinkActive, setIsLinkActive] = React.useState(false);

  useEffect(() => {
    const currentCartItems = cart.items;

    if (currentCartItems && currentCartItems.length > 0) {
      for (let i = 0; i < currentCartItems.length; i++) {
        const current = currentCartItems[i].product;
        if (current.id === id && currentCartItems[i]?.options?.length > 0) {
          setIsLinkActive(true);
          break;
        }
      }
    }
  }, [cart]);

  useEffect(() => {
    if (product.option_groups.length > 0) {
      for (let i = 0; i < product.option_groups.length; i++) {
        let item = product.option_groups[i];
        if (item.min_selected > 0) {
          setIsLinkActive(true);
          break;
        }
      }
    }
  }, []);

  let quantity = 0;
  if (cartItems) {
    quantity = cartItems.reduce((x, y) => x + y["quantity"], 0);
  }

  const cartAddItem = (id) => {
    setIsLoading(true);
    telegramNotificationOccurredSuccess();
    coreAddCartItem(id, 1).then((response) => {
      setCart(response.data);
      setIsLoading(false);
    });
  };

  const cartRemoveItem = (id) => {
    setIsLoading(true);
    telegramNotificationOccurredSuccess();
    coreSetCartItemQuantity(id, cartItem.quantity - 1).then((response) => {
      setCart(response.data);
      setIsLoading(false);
    });
  };

  let button = null;
  if (isLoading) {
    button = (
      <div className="btn-price-wrapper">
        <button className="price-btn  position-absolute bottom-0 ">
          <Spinner size={"sm"} />
        </button>
      </div>
    );
  } else {
    button = !cartItem ? (
      <ProductCardButton price={price} salePrice={sale_price} onClick={() => cartAddItem(id)} />
    ) : (
      <div className="product-card-buttons-wrapper">
        <div className="btn-space position-absolute bottom-0">
          <div className="button-group" style={{ marginLeft: "0 auto" }}>
            <button onClick={() => cartRemoveItem(cartItem.id)}>-</button>
            <input type="text" readOnly value={quantity} />
            <button onClick={() => cartAddItem(id)}>+</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Col xs="6" md="4" lg="2">
      <div
        className={`product-card h-100 position-relative ${
          cartItem ? `incart incart-${Math.min(quantity, 3)}` : ""
        }`}
      >
        <div
          onClick={() => {
            telegramSendSoftTaptic();
            navigate(`/product/${id}`);
          }}
        >
          <ProductCardImage src={photo} />
        </div>

        {is_featured ? <ProductCardFeaturedBage /> : ""}

        {sale_price && !is_featured ? <ProductCardDiscountBadge /> : ""}

        <div className="product-title">{title}</div>

        {isLinkActive && <Link className="product__link-to" to={`/product/${id}`}></Link>}

        <div className="product-description">{description}</div>
        {button}
      </div>
    </Col>
  );
};

export default ProductCard;
