import React from "react";
import styles from "./PopularProductList.module.scss";
import PopularProductListImage from "./PopularProductListImage";
import { useNavigate } from "react-router-dom";
import { telegramSendSoftTaptic } from "../../services/telegram";

const SmallProductCard = ({ item }) => {
  const navigate = useNavigate();

  const onClick = () => {
    telegramSendSoftTaptic();
    navigate(`/product/${item.id}`);
  };

  return (
    <div onClick={onClick} className={styles.PopularProduct__item}>
      <PopularProductListImage photo={item.photo} />
      <div className={styles.PopularProduct__content}>
        <div className={styles.PopularProduct__title}>{item.title}</div>
        <div className={styles.PopularProduct__price}>{item.sale_price || item.price} ₽</div>
      </div>
    </div>
  );
};

export default SmallProductCard;
