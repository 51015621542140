import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { formatPhoneNumber, getFormattedBonuses } from "../services/utils";
import avatarSrc from "../assets/avatar.png";
import { useRestaurantSelector } from "../store/useSelectors";

const ProfileCard = ({ userData, ...props }) => {
  const { settings } = useRestaurantSelector();

  return (
    <Card {...props}>
      <CardBody className="text-center">
        <div className="mb-4">
          <img
            src={userData.user.photo || avatarSrc}
            className="rounded-circle img-fluid"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <h4 className="mb-2" style={{ color: "var(--text-color)" }}>
            {userData.user.first_name + " "}
            {userData.user.last_name || " "}
          </h4>
          {userData.user.is_admin ? (
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Twitter_Verified_Badge.svg/240px-Twitter_Verified_Badge.svg.png"
              alt=""
              width={20}
              height={20}
              style={{ marginLeft: "5px", marginBottom: "10px" }}
            />
          ) : (
            ""
          )}
        </div>
        <p className="mb-2" style={{ color: "var(--hint-color)" }}>
          {formatPhoneNumber(userData.user.phone_number)}
        </p>
        {settings.bonus_enabled ? (
          <p className="mb-2" style={{ color: "var(--hint-color)" }}>
            {getFormattedBonuses(userData.bonuses)}
          </p>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default ProfileCard;
