import React, { useEffect, useState } from "react";

import styles from "./Bonuses.module.scss";
import CustomCheckBox from "../../CustomCheckBox/CustomCheckBox";
import { Input } from "reactstrap";
import { CheckIcon } from "../../ui/icons/CheckIcon";
import { getFormattedBonuses } from "../../../services/utils";
import {
  telegramNotificationOccurredSuccess,
  telegramSelectionChanged,
} from "../../../services/telegram";

const BonusesCard = ({ cart, cartLoading, bonuses, setBonuses }) => {
  const [checked, setChecked] = useState(false);
  const [valueInput, setValueInput] = useState(null);
  const [activeRadio, setActiveRadio] = useState(1);

  const onChangeInput = (value) => {
    if (cartLoading) return;
    telegramSelectionChanged();
    setChecked(value);

    if (value) {
      if (bonuses !== cart.bonuses_can_spend && activeRadio !== 1) {
        setBonuses(valueInput || 0);
      } else {
        setBonuses(cart.bonuses_can_spend);
      }
    } else {
      setBonuses(0);
    }
  };

  const onChangeActiveRadio = (value) => {
    if (cartLoading) return;
    telegramSelectionChanged();
    setActiveRadio(value);
    if (value === 1) setBonuses(cart.bonuses_can_spend);
    if (value === 2) setBonuses(valueInput || 0);
  };

  useEffect(() => {
    if (activeRadio === 2 && !cartLoading) {
      let timeout;
      if (valueInput) {
        timeout = setTimeout(() => {
          setBonuses(valueInput);
        }, 600);
      } else {
        setBonuses(0);
      }

      return () => clearTimeout(timeout);
    }
  }, [valueInput]);

  return (
    <div className={styles.bonuses}>
      <div className={styles.bonuses__header}>
        <div className={styles.bonuses__content}>
          <h6 className="payment-form-title m-0">Использовать бонусы</h6>
          <div className={styles.bonuses__text}>
            Доступно {getFormattedBonuses(cart.bonuses_can_spend)}
          </div>
        </div>
        <CustomCheckBox checked={checked} onChangeInput={onChangeInput} id="bonuses" />
      </div>

      {checked && (
        <div className={styles.bonuses__options}>
          <div className={styles.bonuses__option}>
            <div
              onClick={() => onChangeActiveRadio(1)}
              className={
                activeRadio === 1
                  ? [styles.bonuses__radio, styles.active].join(" ")
                  : styles.bonuses__radio
              }
            >
              <CheckIcon color="var(--secondary-color)" />
            </div>
            <div className="payment-form-title m-0">{cart.bonuses_can_spend}</div>
          </div>
          <div className={styles.bonuses__option}>
            <div
              onClick={() => onChangeActiveRadio(2)}
              className={
                activeRadio === 2
                  ? [styles.bonuses__radio, styles.active].join(" ")
                  : styles.bonuses__radio
              }
            >
              <CheckIcon color="var(--secondary-color)" />
            </div>
            <Input
              placeholder="Другое кол-во"
              type={"number"}
              min={"0"}
              disabled={activeRadio !== 2}
              value={valueInput}
              onChange={(event) => {
                setValueInput(event.target.value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BonusesCard;
