import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { telegramIsRunning, telegramSendSoftTaptic } from "../services/telegram";
import { useRestaurantSelector, useUserSelector } from "../store/useSelectors";
import { MainButton as TelegramMainButton } from "@vkruglikov/react-telegram-web-app";

const MainButton = ({ text, redirectTo, onClick }) => {
  const navigate = useNavigate();
  const { isLoading } = useUserSelector();
  const { settings } = useRestaurantSelector();

  const handleClick = () => {
    telegramSendSoftTaptic();
    onClick && onClick();
    redirectTo && navigate(redirectTo);
  };

  const buttonParams = {};

  if (settings.colors) {
    if (settings?.colors?.button_color) {
      buttonParams.color = settings?.colors?.button_color;
    }
    if (settings?.colors?.button_text_color) {
      buttonParams.textColor = settings?.colors?.button_text_color;
    }
  }

  return telegramIsRunning() ? (
    <TelegramMainButton onClick={handleClick} progress={isLoading} text={text} {...buttonParams} />
  ) : (
    <>
      {redirectTo ? (
        <Link className="telegram-main-button" to={redirectTo} onClick={onClick}>
          {isLoading ? "loading..." : text}
        </Link>
      ) : (
        <div className="telegram-main-button" to={redirectTo} onClick={onClick}>
          {isLoading ? "loading..." : text}
        </div>
      )}
    </>
  );
};

export default MainButton;
