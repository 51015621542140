import { setCssProperty } from "./css";

export const setThemeColors = (colors) => {
  colors?.bg_color && setCssProperty("--bg-color", colors.bg_color);
  colors?.text_color && setCssProperty("--text-color", colors.text_color);
  colors?.hint_color && setCssProperty("--hint-color", colors.hint_color);
  colors?.button_color && setCssProperty("--button-color", colors.button_color);
  colors?.button_text_color && setCssProperty("--button-text-color", colors.button_text_color);
  colors?.secondary_bg_color && setCssProperty("--secondary-bg-color", colors.secondary_bg_color);

  // Цвета для TelegramMainButton ставятся в <MainButton />
};
