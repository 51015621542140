import { createSlice } from "@reduxjs/toolkit";

const OrderSlice = createSlice({
  name: "order",
  initialState: {
    data: null,
  },
  reducers: {
    setOrderData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default OrderSlice;
