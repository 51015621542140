import React from "react";

import ChatupperLogo from "../assets/chatupper.png";
import { telegramOpenLink } from "../services/telegram";
import { useRestaurantSelector } from "../store/useSelectors";

const Copyright = () => {
  const { restaurantId } = useRestaurantSelector();

  let link = `https://cafebotum.com?utm_source=cafebotum&utm_medium=menu-app`;

  if (restaurantId) {
    link += "&utm_content=restaurant_id-" + restaurantId;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        telegramOpenLink(link);
      }}
    >
      <img src={ChatupperLogo} alt="logo" style={{ height: "2rem" }} />
      <span
        style={{
          opacity: "50%",
          marginLeft: "0.7rem",
          fontSize: "0.7rem",
          fontWeight: "800",
        }}
      >
        Кафеботум
      </span>
    </div>
  );
};

export default Copyright;
