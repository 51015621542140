import React from "react";

const VerticalButtons = (props) => {
  return (
    <>
      <ul className="vertical-btn-group">
        {props.optionsArr.map((optionName, i) => (
          <li
            key={i}
            onClick={() => props.onClickOption(i)}
            className={props.activeOption === i ? "active" : ""}
          >
            {optionName}
          </li>
        ))}
      </ul>
    </>
  );
};

export default VerticalButtons;
