import React from "react";
import ImageWithLoader from "../ImageWithLoader";

const ProductPageImage = (props) => {
  return (
    <ImageWithLoader
      className="w-100 product-page__img"
      src={props.src}
      alt="product"
      loading="lazy"
    />
  );
};

export default ProductPageImage;
