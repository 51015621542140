import { createSlice } from "@reduxjs/toolkit";

const CacheSlice = createSlice({
  name: "cache",
  initialState: {
    deliveryAreas: null,
    userDeliveryAddresses: null,
  },
  reducers: {
    setDeliveryAreas: (state, action) => {
      state.deliveryAreas = action.payload;
    },
    setUserDeliveryAddresses: (state, action) => {
      state.userDeliveryAddresses = action.payload;
    },
  },
});

export default CacheSlice;
