import { Route, Routes, useNavigate } from "react-router-dom";

import React, { useEffect } from "react";
import { telegramSendSoftTaptic, telegramShowAlert, webApp } from "./services/telegram";
import MenuPage from "./pages/MenuPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import OrderPage from "./pages/OrderPage";
import OrderListPage from "./pages/OrderListPage";
import TestPage from "./pages/TestPage";
import { useRestaurantSelector, useUISelector, useUserSelector } from "./store/useSelectors";
import { coreGetCurrentRestaurant } from "./api/core";
import { useRestaurantActions, useUserActions } from "./store/useActions";
import Loader from "./components/Loader";
import CreateDeliveryAddressPage from "./pages/CreateDeliveryAddressPage";
import { fetchUserToken } from "./services/api/users";
import ProductPage from "./pages/ProductPage";
import DeliveryAddressChoosePage from "./pages/DeliveryAddressChoosePage/DeliveryAddressChoosePage";
import OrderPayPage from "./pages/OrderPayPage";

// Пути, для которых не нужно отображать Loader
const WITHOUT_LOADER_PATHS = new Set(["/"]);

const App = () => {
  const navigate = useNavigate();
  const { hideBackButton } = useUISelector();
  const { isLoaded } = useRestaurantSelector();
  const { setRestaurant, setIsLoaded } = useRestaurantActions();
  const { token } = useUserSelector();

  const withoutLoader = WITHOUT_LOADER_PATHS.has(window.location.pathname);

  const handleGoBack = () => {
    navigate(-1);
    telegramSendSoftTaptic();
  };

  useEffect(() => {
    webApp.BackButton.onClick(handleGoBack);

    if (!isLoaded) {
      coreGetCurrentRestaurant()
        .then((response) => {
          setRestaurant(response.data);
          window.scrollTo({ top: 0 });
        })
        .catch((err) => {
          telegramShowAlert(err);
        })
        .finally(() => {
          fetchUserToken();
        });
    }
  }, []);

  useEffect(() => {
    if (!token) return;
    setIsLoaded(true);
  }, [token]);

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/orders/" ||
      window.location.pathname === "/orders"
    ) {
      webApp.BackButton.hide();
    } else {
      if (!hideBackButton) {
        webApp.BackButton.show();
      }
    }
  });

  useEffect(() => {
    if (hideBackButton) {
      webApp.BackButton.hide();
    }
  }, [hideBackButton]);

  if (!isLoaded && !withoutLoader) {
    return <Loader atCenter={true} />;
  }

  return (
    <>
      {/*TODO: Раскоментить потом, мешает разработке */}
      {/*<YandexMetrika />*/}
      <Routes>
        <Route index path="/" element={<MenuPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/orders" element={<OrderListPage />} />
        <Route path="/orders/:id" element={<OrderPage />} />
        <Route path="/orders/:id/pay" element={<OrderPayPage />} />

        <Route path="/product/:id" element={<ProductPage />} />

        <Route path="/delivery-address/new" element={<CreateDeliveryAddressPage />} />
        <Route path="/delivery-address/choose" element={<DeliveryAddressChoosePage />} />

        <Route path="/test" element={<TestPage />} />
      </Routes>
    </>
  );
};

export default App;
