import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ImageWithLoader from "../ImageWithLoader";
import { getPhotoThumbnail } from "../../services/media";
import styles from "./OrderItemCard.module.scss";
import { sortOptions } from "../../utils/sortOptions";
import { MoneyFormat } from "../../services/utils";

const OrderItemCard = ({ item }) => {
  return (
    <Card className={styles.orderitem}>
      <CardBody className="cart__body">
        <Row className="gap-2">
          <Col xs={2} className="p-0">
            <ImageWithLoader
              className="cart-item-img"
              src={getPhotoThumbnail(item.product.photo)}
              loaderStyle={{ height: "10vh" }}
            />
          </Col>
          <Col className={"col"}>
            <Row>
              <Col xs={12} className={"mb-2 p-0"}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={7}>
                    <div className={styles.orderitem__title}>{item.product.title}</div>
                  </Col>
                  <Col xs={4} className={"align-items-end"}>
                    <div
                      className={"text-center h6 m-0"}
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "var(--secondary-bg-color)",
                        borderRadius: "15px",
                        color: "var(--hint-color)",
                      }}
                    >
                      x{item.quantity}
                    </div>
                  </Col>
                </Row>
              </Col>

              {item?.options?.length > 0 && (
                <Col xs={12} className={`p-0 mb-3 ${styles.negative_margin}`}>
                  {sortOptions(item.options).map((optionGroup) => {
                    return (
                      <div className="mb-1">
                        <p style={{ fontSize: "0.9rem" }} className="mb-0">
                          {optionGroup.optionGroupTitle}:
                        </p>
                        <ul className="m-0" style={{ paddingLeft: "1rem", listStyle: "disc" }}>
                          {optionGroup.options.map((option) => (
                            <li className="d-flex align-items-center cart-item__add text-break">
                              <p style={{ marginBottom: 0 }}>{option.title}</p>
                              <span>+ {option.price} ₽</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </Col>
              )}

              <Col xs={12} className={`p-0 ${styles.negative_margin}`}>
                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <Row className="d-flex align-items-center">
                    <Col className="col-auto">
                      <div className={"h6 mb-0"} style={{ alignItems: "center" }}>
                        {MoneyFormat.format(item.price)}
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderItemCard;
