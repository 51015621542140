import React from "react";
import { Badge, Card, CardBody } from "reactstrap";
import strftime from "strftime";
import {
  deliveryTypeMap,
  paymentMethodMap,
  stateToBadgeColorMap,
  stateToTextMap,
} from "../data/consts";

const OrderCard = ({ order, ...props }) => {
  return (
    <Card {...props}>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6>Заказ #{order.id}</h6>
          <Badge tag={"div"} color={stateToBadgeColorMap[order.state]}>
            {stateToTextMap[order.state]}
          </Badge>
        </div>

        <div className="d-flex justify-content-between">
          <h5>{order.cost} ₽</h5>
          <div className="card-order__date">
            {strftime("%H:%M | %d.%m.%Y", new Date(Date.parse(order.created_at)))}
          </div>
        </div>
        {order.address ? (
          <div className="address mb-2 lh-sm" style={{ color: "var(--hint-color)" }}>
            {order.address.data.value}
          </div>
        ) : null}
        {order.table_number ? (
          <div className="address mb-2 lh-sm" style={{ color: "var(--hint-color)" }}>
            Столик №{order.table_number}
          </div>
        ) : null}
        <div className="d-flex opacity-50">
          {deliveryTypeMap[order.delivery_type]} · {paymentMethodMap[order.payment_method]}
        </div>
      </CardBody>
    </Card>
  );
};

export default OrderCard;
