export const CheckIcon = ({ color, className }) => {
  return (
    <svg
      fill="none"
      height="20"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
      strokeWidth="2"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
};
